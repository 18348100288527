import { EAction } from "@/common";
import { MenuAction } from "@/components/MenuAction";
import { ROUTES } from "@/constants";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BookIcon from "@mui/icons-material/Book";

interface WordbookCardProps {
  isAdmin?: boolean;
  item: {
    _id: string;
    name: string;
    vocabCount: number;
  };
  handleAction?: (action: { typeAction: EAction }) => void;
  onlyView?: boolean;
  onClickItem?: () => void;
}

const WordbookCard: React.FC<WordbookCardProps> = (props) => {
  const navigate = useNavigate();
  const { isAdmin, item, handleAction, onlyView, onClickItem } = props;

  const onGoToWordbookPage = () => {
    if (onClickItem) {
      onClickItem();
      return;
    }
    if (isAdmin) {
      navigate(ROUTES.CMS_WORKBOOK.replace(":wordbookId", item._id));
    } else {
      navigate(ROUTES.WORDBOOK.replace(":wordbookId", item._id));
    }
  };
  return (
    <Card
      sx={{
        cursor: "pointer",
      }}
      onClick={onGoToWordbookPage}
    >
      <CardContent
        sx={{
          padding: "10px!important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              "& svg": {
                "& path": {
                  fill: "#264653",
                },
              },
            }}
          >
            <BookIcon fontSize="large" />
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {item.name}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontWeight: 300,
                  fontSize: 12,
                }}
              >
                {item.vocabCount} words
              </Typography>
            </Box>
          </Box>
          {!onlyView && (
            <MenuAction
              handleClick={(typeAction: EAction) => {
                handleAction && handleAction({ typeAction });
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default WordbookCard;
