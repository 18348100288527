import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/common";
import { Box, Button, CircularProgress, IconButton, Input, useTheme } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { extractContent, randomStringFromDatetime, refinePrompt } from "@/helpers";
import ListIdeaAI from "./ListIdeaAI";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";

interface KeywordToIdeaProps {
  isPartTwo?: boolean;
  question: IItems | null;
}
const KeywordToIdea = ({ question, isPartTwo }: KeywordToIdeaProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [inputAns, setInputAns] = useState("");
  const [idea, setIdea] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitAns = async () => {
    setIsLoading(true);

    try {
      const apiUrl = getApiUrl(isPartTwo ? ENDPOINTS.KEYWORD_TO_IDEA_PART_2 : ENDPOINTS.KEYWORD_TO_IDEA);
      const res = await Requests.post(apiUrl, {
        question: refinePrompt(extractContent(question?.name ?? "")),
        keyword: inputAns,
      });
      if (isPartTwo) {
        if (res.data?.result) {
          setIdea((res.data?.result ?? "").toString());
        }
      } else {
        if (res.data?.result?.length) {
          const item = res.data?.result[0];
          setIdea({
            genByAI: true,
            _id: randomStringFromDatetime(),
            name: `${item.title} (${item.detail})`,
            description: item.example_answer,
            locationIdea: "",
          });
        }
      }
    } catch (e) {}
    setIsLoading(false);
  };
  return (
    <Box
      sx={
        isPartTwo
          ? {
              overflowY: "auto",
              maxHeight: "calc(100vh - 85px)",
            }
          : {}
      }
    >
      <Box
        sx={{
          backgroundColor: "#F3F3FF",
          borderRadius: "5px",
          padding: "10px",
          "& p": {
            color: "#313131",
            fontSize: "14px",
          },
        }}
      >
        <p>
          <b>Miêu tả tính năng:</b> Viết ra ý tưởng để trả lời câu hỏi dựa trên từ khóa người học cung cấp
          <br />
          <b>Đối tượng sử dụng:</b> Công cụ này phù hợp với{" "}
          <b
            style={{
              textDecoration: "underline",
            }}
          >
            người học có ý tưởng
          </b>{" "}
          trả lời câu hỏi{" "}
          <b
            style={{
              textDecoration: "underline",
            }}
          >
            nhưng chưa có từ vựng để liên kết các ý tưởng
          </b>{" "}
          đó
          <br />
          <b>Cách sử dụng:</b> Nhập từ, cụm từ hoặc câu trả lời vào ô trống, bấm nút Gen idea ở góc dưới bên phải
        </p>
      </Box>
      <Box
        sx={{
          position: "relative",
          margin: "10px 0",
        }}
      >
        <Input
          multiline
          rows={3}
          sx={{
            flex: 1,
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            padding: "10px 30px 10px 10px",
            width: "100%",
            "&::after": {
              display: "none",
            },
            "&::before": {
              display: "none",
            },
          }}
          value={inputAns}
          onChange={(event) => setInputAns(event.target.value)}
          //   onKeyDown={(e) => {
          //     if (e.key === "Enter") {
          //       onSubmitAns();
          //     }
          //   }}
          placeholder="Enter my answer ..."
        />
        <IconButton
          className="absolute bottom-0 right-0"
          disabled={isLoading || inputAns.trim() === ""}
          onClick={() => onSubmitAns()}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              sx={{
                marginRight: "10px",
              }}
            />
          ) : (
            <SendIcon
              sx={
                inputAns.trim() === ""
                  ? {}
                  : {
                      color: theme.palette.primary.main,
                    }
              }
            />
          )}
        </IconButton>
      </Box>
      {idea && (
        <>
          {isPartTwo ? (
            <Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              >
                Gợi ý
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                }}
              >
                {idea}
              </Box>
              <Button
                variant="contained"
                sx={{
                  background: theme.palette.primary.main,
                  color: "#fff",
                  textTransform: "unset",
                  float: "right",
                }}
                onClick={() => {
                  navigate(ROUTES.ANSWER_QUESTION.replace(":questionId", question?._id ?? ""), {
                    state: { isPartTwo: true, question, contentSuggestion: idea },
                  });
                }}
              >
                Customize your answer
              </Button>
            </Box>
          ) : (
            <ListIdeaAI ideas={[idea]} />
          )}
        </>
      )}
    </Box>
  );
};

export default KeywordToIdea;
