import React, { useMemo, useState } from "react";
import { Box, Button, Modal, TextField, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import { useInsertTermsWordbook } from "@/services-react-query/queries/wordbank.query";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";

// Define the type for each word-meaning pair
interface WordMeaningPair {
  term: string;
  definition: string | null;
}

interface WordMeaningModalProps {
  open: boolean;
  onClose: (checkSuccess?: boolean) => void;
  wordbookId?: string;
}

const WordMeaningModal: React.FC<WordMeaningModalProps> = ({ open, onClose, wordbookId }) => {
  const [delimiter, setDelimiter] = useState<string>("-");
  const [entrySeparator, setEntrySeparator] = useState<string>("newline");
  const [inputData, setInputData] = useState<string>("");
  //   const [preview, setPreview] = useState<WordMeaningPair[]>([]);
  const dispatch = useDispatch();
  const { mutate: insertTermsWordbook, isLoading } = useInsertTermsWordbook({
    onSuccess: () => {
      dispatch(showMessage("Insert word success", "success"));
      onClose(true);
    },
  });

  const handleDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDelimiter(event.target.value);
  };

  const handleEntrySeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntrySeparator(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setInputData(data);
  };

  const preview = useMemo(() => {
    const lineSeparator = "\n\n\n";
    const previewData: WordMeaningPair[] = inputData.split(lineSeparator).map((line) => {
      const [term, definition] = line.split("\n\n");
      return { term: term?.trim() || "", definition: definition?.trim() || null };
    });
    return previewData;
  }, [delimiter, entrySeparator, inputData]);

  const handleSave = () => {
    // console.log("Saved Data:", preview);
    insertTermsWordbook({
      id: wordbookId,
      items: preview,
    });
    // onClose();
  };
  const getPlaceholder = (): string => {
    const exampleDelimiter = "\n\n";
    const entrySeparatorText = "\n\n\n";
    return `Từ 1${exampleDelimiter}Định nghĩa 1${entrySeparatorText}Từ 2${exampleDelimiter}Định nghĩa 2`;
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Nhập dữ liệu
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          Chép và dán dữ liệu ở đây (từ Word, Excel, Google Docs, v.v.)
        </Typography>
        <TextField
          multiline
          rows={9}
          fullWidth
          variant="outlined"
          placeholder={getPlaceholder()}
          value={inputData}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
        />
        <Typography variant="body1" sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
          Giữa thuật ngữ và định nghĩa:
          <Typography variant="body1" component={"span"} sx={{ mb: 1, fontWeight: "400" }}>
            1 dòng trắng
          </Typography>
        </Typography>

        {/* <RadioGroup row value={delimiter} onChange={handleDelimiterChange}>
          <FormControlLabel value="Tab" control={<Radio />} label="Tab" />
          <FormControlLabel value="," control={<Radio />} label="Phẩy" />
          <FormControlLabel value="-" control={<Radio />} label="-" />
        </RadioGroup> */}
        <Typography variant="body1" sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
          Giữa các thẻ:{" "}
          <Typography variant="body1" component={"span"} sx={{ mb: 1, fontWeight: "400" }}>
            2 dòng trắng
          </Typography>
        </Typography>

        {/* <RadioGroup row value={entrySeparator} onChange={handleEntrySeparatorChange}>
          <FormControlLabel value="newline" control={<Radio />} label="Dòng mới" />
          <FormControlLabel value="comma" control={<Radio />} label="Chấm phẩy" />
        </RadioGroup> */}
        <Typography variant="body1" sx={{ mt: 3 }}>
          Xem trước:
        </Typography>
        <Box
          sx={{
            maxHeight: 150,
            overflowY: "auto",
            bgcolor: "background.default",
            p: 2,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1,
            mt: 1,
          }}
        >
          {preview.length === 0 ? (
            <Typography variant="body2" color="text.secondary">
              Không có nội dung để xem trước
            </Typography>
          ) : (
            preview.map((item, index) => (
              <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>
                <b>{item.term}</b>: {item.definition || "(Không có định nghĩa)"}
              </Typography>
            ))
          )}
        </Box>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button variant="outlined" onClick={() => onClose()}>
            Hủy nhập
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Nhập
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WordMeaningModal;
