import { useEffect, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import CategoryAspectPage from "../CategoryAspectPage";
import { useDispatch, useSelector } from "react-redux";
import {
  AllTypeActions,
  EAction,
  ERole,
  ETypeItem,
  IApplicationState,
  ICreateItem,
  IItems,
  createItems,
  deleteItem,
  getDetailItem,
  getListIdeas,
  removeMultipleItems,
  resetData,
  updateItem,
} from "../../../store-redux";
import { useParams } from "react-router-dom";
import { styles } from "./styles";
import CreateEditItemModal, { IFormDataProps } from "../../../components/CreateEditItemModal";
import NoDataComponent from "../../../components/NoDataComponent";
import ListIdeas, { IHandleAction } from "./components/ListIdeas";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FormGenerateIdea } from "./components/FormGenerateIdea";
import { extractContent } from "../../../helpers";
import BackgroundKnowledgeModal from "@/components/BackgroundKnowledgeModal";

function QuestionIdeaPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: idQuestion } = useParams();
  const [selectedAspectId, setSelectedAspectId] = useState<string | null>(null);
  const [openModalCreate, setOpenModalCreate] = useState<{
    open: boolean;
    data?: IItems;
  }>({
    open: false,
  });
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);

  const currentInfoUser = useSelector((state: IApplicationState) => state.currentUser);
  const createItemsResult = useSelector((state: IApplicationState) => state.createItems);
  const listIdeasResult = useSelector((state: IApplicationState) => state.getListIdeas);
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);
  const deleteItemResult = useSelector((state: IApplicationState) => state.deleteItem);
  const updateItemResult = useSelector((state: IApplicationState) => state.updateItem);
  const removeMultipleItemsResult = useSelector((state: IApplicationState) => state.removeMultipleItems);
  const [showSearchAI, setShowSearchAI] = useState(false);

  const handleGetListIdeas = (idAspect?: string) => {
    dispatch(
      getListIdeas({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        parentId: idQuestion,
        aspectId: `${idAspect ?? selectedAspectId}`,
      })
    );
  };

  const onClickAspect = (idAspect: string) => {
    setSelectedAspectId(idAspect);
    idAspect && handleGetListIdeas(idAspect);
  };

  const handleCreateEdit = (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item) => ({
      name: item.title,
      description: item.description,
      type: item.type as ETypeItem,
      parentId: `${idQuestion}`,
      aspectId: `${selectedAspectId}`,
    }));
    if (openModalCreate.data) {
      dispatch(updateItem({ ...dataSend?.[0], id: openModalCreate.data._id }));
    } else {
      dispatch(createItems({ items: dataSend }));
    }
  };

  const getContentAspect = (): string => {
    const valueFind = listAspects.data?.find((item) => item._id === selectedAspectId)?.name;
    return extractContent(valueFind ?? "");
  };
  const getContentQuestion = (): string => {
    const valueFind = extractContent(detailQuestion.data?.name ?? "");
    return valueFind ?? "";
  };

  const handleAction = ({ typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteItem(data._id));
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        open: true,
        data,
      });
    }
  };

  const handleDeleteMultiple = () => {
    Swal.fire({
      title: "Are you sure to delete items selected?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          removeMultipleItems({
            items: selectedItems,
          })
        );
      }
    });
  };

  const handleBack = () => {
    navigate("/cms/topic-question");
  };

  const handleCloseModal = () => {
    setOpenModalCreate({
      open: false,
    });
  };

  //GET DETAIL
  useEffect(() => {
    dispatch(getDetailItem(`${idQuestion}`));
  }, []);

  // CREATE
  useEffect(() => {
    if (createItemsResult.data && !createItemsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_ITEMS));
      handleGetListIdeas();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createItemsResult]);

  // DELETE
  useEffect(() => {
    if (deleteItemResult.data && !deleteItemResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_ITEM));
      handleGetListIdeas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItemResult]);

  // UPDATE
  useEffect(() => {
    if (updateItemResult.data && !updateItemResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_ITEM));
      handleGetListIdeas();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemResult]);

  // REMOVE MULTIPLE
  useEffect(() => {
    if (removeMultipleItemsResult.data && !removeMultipleItemsResult.error) {
      setSelectedItems([]);
      dispatch(resetData(AllTypeActions.REMOVE_MULTIPLE_ITEMS));
      handleGetListIdeas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeMultipleItemsResult]);

  return (
    <Box>
      {detailQuestion.isFetching ? (
        <LinearProgress color="secondary" style={{ marginRight: 10 }} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: "8px",
            marginBottom: "20px",
            "& .btn-back": {
              padding: "10px",
              marginRight: "20px",
              textTransform: "none",
              fontSize: "16px",
            },
          }}
        >
          <Button variant="contained" className="btn-back" onClick={() => handleBack()} color="secondary">
            Back
          </Button>
          <Box
            dangerouslySetInnerHTML={{
              __html: detailQuestion.data?.name || "",
            }}
            sx={{
              backgroundColor: "#e1e1e1",
              padding: "10px",
              marginRight: "20px",
              flex: 1,
            }}
          />
          <BackgroundKnowledgeModal detail={detailQuestion.data} />
        </Box>
      )}
      <Box
        sx={{
          ...styles.boxContainer,
          "& >div:first-of-type": {
            flex: 1,
          },
          "& .col-idea": {
            flex: 2,
          },
          position: "relative",
        }}
      >
        <CategoryAspectPage
          isPageQuestion
          onClickAspect={onClickAspect}
          onClickSearchAI={() => setShowSearchAI((prev) => !prev)}
          showSearchAI={showSearchAI}
        />
        <Box
          className="col-idea"
          sx={
            showSearchAI
              ? {
                  display: "none",
                }
              : {}
          }
        >
          <Typography variant="h3">Idea</Typography>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setOpenModalCreate({
                  open: true,
                });
              }}
              color="secondary"
              disabled={!selectedAspectId}
            >
              Create ideas
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              variant="contained"
              onClick={() => {
                if (showSelect) {
                  setSelectedItems([]);
                }
                setShowSelect(!showSelect);
              }}
              color="warning"
              disabled={!selectedAspectId}
            >
              {showSelect ? "Deselect" : "Select"} Multiple
            </Button>
            {showSelect && selectedItems.length && selectedAspectId ? (
              <Button
                sx={{ marginLeft: "10px" }}
                variant="contained"
                onClick={handleDeleteMultiple}
                color="error"
                disabled={!selectedAspectId}
              >
                Delete All
              </Button>
            ) : null}
          </Box>
          <Box className="box-item">
            {selectedAspectId ? (
              <>
                {currentInfoUser.data?.role === ERole.SUPER_ADMIN ? (
                  <FormGenerateIdea
                    aspectContent={getContentAspect()}
                    questionContent={getContentQuestion()}
                    questionId={idQuestion ?? ""}
                    aspectId={selectedAspectId}
                    handleGetListIdeas={handleGetListIdeas}
                  />
                ) : null}
                {listIdeasResult.data?.length ? (
                  <ListIdeas
                    listData={listIdeasResult.data}
                    handleAction={handleAction}
                    setOpenModalCreate={setOpenModalCreate}
                    showSelect={showSelect}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    aspectId={selectedAspectId}
                    aspectContent={getContentAspect()}
                    handleGetListIdeas={handleGetListIdeas}
                  />
                ) : null}
              </>
            ) : (
              <NoDataComponent />
            )}
          </Box>
        </Box>
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={handleCloseModal}
        isLoading={createItemsResult.isFetching}
        titleModal={openModalCreate?.data ? `Edit idea` : `Create new ideas`}
        data={openModalCreate.data}
        isIdea
      />
    </Box>
  );
}

export default QuestionIdeaPage;
