import { BASE_DICT_SOUND_UK, BASE_DICT_SOUND_US } from "@/constants";
import { onPlayReadStr } from "@/helpers";
import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import { Box, IconButton, Table, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { MenuAction } from "@/components/MenuAction";
import { EAction } from "@/common";

const ListVocabsListView = (props) => {
  const { items, handleClickAction } = props;
  const [viewMore, setViewMore] = useState([]);

  const onPlaySound = (item: any, type: string) => {
    if (item.word?.uid) {
      const baseSoundByType = {
        us: BASE_DICT_SOUND_US,
        uk: BASE_DICT_SOUND_UK,
      } as const; // Ensure baseSoundByType is treated as a constant object
      const soundUrl = `${baseSoundByType[type as "us" | "uk"]}${item.word.uid}.mp3`; // Type assertion for type
      const audio = new Audio(soundUrl);
      audio.play();
    } else {
      onPlayReadStr(item.term ?? item.word?.word, undefined, type === "us" ? "en-US" : "en-GB");
    }
  };
  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 240px)",
        overflowY: "auto",
      }}
    >
      <Table>
        {items.map((item: any, index: number) => {
          return (
            <TableRow key={index}>
              <TableCell
                sx={{
                  alignContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {item.term ?? item.word?.word ?? ""}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  alignContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ...(viewMore.includes(item?._id) ? {} : { maxHeight: "120px" }),
                  }}
                  onClick={() => {
                    setViewMore((prev) =>
                      prev.includes(item?._id) ? prev.filter((itemP) => itemP !== item._id) : [...prev, item._id]
                    );
                  }}
                >
                  <WordDetail
                    isHidePhonetic={true}
                    word={
                      item.term
                        ? {
                            ...item.word?.word,
                            word: item.term,
                            content: [
                              {
                                text: item.definition,
                              },
                            ],
                          }
                        : item.word ?? {}
                    }
                  />
                </Box>
              </TableCell>
              <TableCell sx={{ alignContent: "flex-start" }}>
                <Box
                  sx={() => ({
                    "& .phonetic_type": {
                      fontWeight: "bold",
                    },
                    "& .phonetic_text": {},
                  })}
                >
                  <Box
                    sx={() => ({
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      "& span:first-of-type": {
                        fontWeight: "bold",
                      },
                    })}
                  >
                    <span>US</span>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        onPlaySound(item, "us");
                        e.stopPropagation();
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                    {item.word && (
                      <span>
                        {item.word.phonetic?.us
                          ? item.word.uid
                            ? `/${item.word.phonetic?.us}/`
                            : `${item.word.phonetic?.us}`
                          : ""}
                      </span>
                    )}
                  </Box>
                  <Box
                    sx={() => ({
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      "& span:first-of-type": {
                        fontWeight: "bold",
                      },
                    })}
                  >
                    <span>UK</span>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        onPlaySound(item, "uk");
                        e.stopPropagation();
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                    {item.word && (
                      <span>
                        {item.word.phonetic?.uk
                          ? item.word.uid
                            ? `/${item.word.phonetic?.uk}/`
                            : `${item.word.phonetic?.uk}`
                          : ""}
                      </span>
                    )}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <MenuAction
                  handleClick={(typeAction: EAction) => {
                    handleClickAction(typeAction, item);
                  }}
                  hide={[EAction.edit]}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </Box>
  );
};

export default ListVocabsListView;
