import { Box, Button, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CreateWordbookBtn from "./components/CreateWordbookBtn";
import { useDeleteWordbook, useGetListWordbooks } from "@/services-react-query/queries/wordbank.query";
import { EAction, showMessage } from "@/store-redux";
import WordbookCard from "./components/wordbooks/WordbookCard";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import FormWordbookModal from "./components/FormWordbookModal";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import ListWordbookCommunity from "./components/ListWordbookCommunity";
const WordbankPage = (props) => {
  const dispatch = useDispatch();
  const [wordBooks, setWordBooks] = useState([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const { mutate: getListWordbooks, isLoading: loading } = useGetListWordbooks({
    onSuccess: (data: any) => {
      setWordBooks(data);
    },
  });

  const { mutate: deleteWordbook, isLoading: isLoadingDeleteWorkbook } = useDeleteWordbook({
    onSuccess: (id: any) => {
      setWordBooks((prev) => prev.filter((item) => item?._id !== id));
      dispatch(showMessage("Delete wordbook success", "success"));
    },
  });

  useEffect(() => {
    getListWordbooksFnc();
  }, []);
  const getListWordbooksFnc = () => {
    getListWordbooks();
  };

  const handleAction = ({ item, typeAction }) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteWordbook(item._id);
        }
      });
    }
    if (typeAction === EAction.edit) {
      setOpenFormModal(item);
    }
  };

  const onUpdateList = (data, isNew) => {
    // getListWordbooks()
    if (isNew) setWordBooks((prev) => [data, ...prev]);
    else setWordBooks((prev) => prev.map((item) => (item._id === data._id ? { ...item, ...data } : item)));
  };

  const nbSavedWords = useMemo(() => {
    return wordBooks.reduce((temp, currentItem) => {
      return temp + (currentItem.vocabCount ?? 0);
    }, 0);
  }, [wordBooks]);
  return (
    <Box
      sx={() => ({
        paddingInline: "16px",
        height: "calc(100vh - 180px)",
        "& h2": {
          height: "54px",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "54px",
          marginRight: "10px",
        },
      })}
    >
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography component={"h2"} className="text">
          Your Wordbank
        </Typography>
        <IconButton onClick={() => getListWordbooks()}>
          <RefreshIcon />
        </IconButton>
      </Box>
      {loading && <LinearProgress />} */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& button": {
            minWidth: "68px",
            height: "30px",
            padding: "0px 8px",
            borderRadius: "4px",
            color: "white",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "30px",
            textTransform: "none",
          },
        }}
      >
        <Box
          sx={{
            "& span": {
              fontWeight: 600,
            },
          }}
        >
          <span>{wordBooks.length}</span> Wordbook | <span>{nbSavedWords}</span> Saved Words
          {/* | 0 Mastered */}
        </Box>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpenFormModal(true)}>
          Create Wordbook
        </Button>
      </Box>
      {/* <CreateWordbookBtn onForceUpdate={() => getListWordbooks()}/> */}
      {!!openFormModal && (
        <FormWordbookModal
          onClose={(data) => {
            if (data) {
              onUpdateList(data, openFormModal === true);
            }
            setOpenFormModal(false);
          }}
          init={openFormModal === true ? null : openFormModal}
        />
      )}
      <Grid container>
        {wordBooks.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              sx={{
                padding: "10px",
              }}
            >
              <WordbookCard
                item={item}
                handleAction={({ typeAction }) => {
                  handleAction({ item, typeAction });
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <ListWordbookCommunity />
    </Box>
  );
};
export default WordbankPage;
