import LayoutTab from "@/components/LayoutTab";
import { ROUTES } from "@/constants";
import { extractContent, sortItemOrder } from "@/helpers";
import {
  ETypeItem,
  getDetailItem,
  getListAspects,
  getListCategories,
  IApplicationState,
  parseParamUrl,
} from "@/store-redux";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import ListSuggestionAspect from "./ListSuggestionAspect";
import MyIdeaPath from "./MyIdeaPath";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AnswerAspectToIdea from "./AnswerAspectToIdea";
import AssistantIcon from "@mui/icons-material/Assistant";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import WordbookQuestion from "./WordbookQuestion";
import BackgroundKnowledgeDetail from "@/components/BackgroundKnowledgeDetail";
enum EAspectView {
  answer_by_aspect = "answer_by_aspect",
  sugesstion_by_aspect = "sugesstion_by_aspect",
}
const LearnQuestionPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { questionId } = useParams();

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const listCategory = useSelector((state: IApplicationState) => state.listCategories);
  const listAspectPage = useSelector((state: IApplicationState) => state.listAspects);
  const [categorySelectedId, setCategorySelectedId] = useState<string | null>(null);
  const [aspectSelectedId, setAspectSelectedId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const [aspectViewType, setAspectViewType] = useState<EAspectView>(EAspectView.answer_by_aspect);
  const [openWordbook, setOpenWordbook] = useState(false);

  useEffect(() => {
    if (questionId && detailQuestion.data?._id !== questionId) {
      dispatch(getDetailItem(questionId));
    }
    if (!listCategory.data?.length) {
      dispatch(
        getListCategories({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.category,
          isOnlyPublic: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (categorySelectedId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: categorySelectedId,
          questionId: questionId,
          type: ETypeItem.aspect,
        })
      );
    }
  }, [categorySelectedId]);
  const listSortedCategories = useMemo(() => {
    return sortItemOrder(listCategory.data);
  }, [listCategory]);
  const categorySelected = listCategory.data?.find((item) => item._id === categorySelectedId) ?? null;

  // const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        "& h2.layout_title": {
          fontSize: "24px",
        },
        "& .layout_title_container": {
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
          "&>div": {},
        },
      }}
    >
      <PanelGroup direction="horizontal">
        <Panel defaultSize={openWordbook ? 0 : 50}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <LayoutTab
              listTab={listSortedCategories.map((category) => {
                return {
                  id: category._id,
                  label: extractContent(category?.name ?? ""),
                };
              })}
              breadcrumbs={[
                {
                  text: "Learn",
                  link: ROUTES.LEARN,
                },
                {
                  text: `Topic & Question`,
                  link:
                    typeQuestion === "P2"
                      ? ROUTES.LEARN_PART_2
                      : parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
                          "type-question": typeQuestion,
                        }),
                },
                {
                  text: `Ideas`,
                },
              ]}
              title={extractContent(detailQuestion.data?.name ?? "")}
              subTitle={
                <>
                  <Box
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#eee",
                      padding: "8px",
                      marginTop: "8px",
                      color: "#2e2d2d",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Four-Category Approach
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F3F3FF",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "8px",
                      "& p": {
                        color: "#313131",
                        fontSize: "14px",
                        // overflow: "hidden",
                        // display: "-webkit-box",
                        // WebkitLineClamp: isExpanded ? "none" : 1,
                        // WebkitBoxOrient: "vertical",
                      },
                    }}
                  >
                    <p>
                      <b>Miêu tả tính năng:</b> Viết ra ý tưởng để trả lời câu hỏi dựa trên từ khóa người học cung cấp
                      với từng khía cạnh của phương pháp 4 đỉnh
                      <br />
                      <b>Đối tượng sử dụng:</b> Công cụ này phù hợp với{" "}
                      <b
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        người học chưa có ý tưởng
                      </b>{" "}
                      trả lời câu hỏi{" "}
                      <b
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        nhưng chưa có từ vựng để liên kết các ý tưởng
                      </b>{" "}
                      đó
                      <br />
                      <b>Cách sử dụng:</b> Nhập từ, cụm từ hoặc câu trả lời vào ô trống của từng khía cạnh, bấm nút “Gen
                      idea” ở phía dưới
                    </p>
                    {/* <Button
                      onClick={() => setIsExpanded(!isExpanded)}
                      sx={{
                        fontSize: "12px",
                        textTransform: "unset",
                      }}
                    >
                      {isExpanded ? "See Less" : "See More"}
                    </Button> */}
                  </Box>
                </>
              }
              tabValue={categorySelectedId ?? false}
              setTabValue={(id) => setCategorySelectedId(id as string | null)}
              titleAction={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Button
                    variant="text"
                    color="success"
                    sx={{
                      fontSize: "14px",
                      textTransform: "unset",
                      padding: "4px",
                    }}
                    onClick={() => {
                      setOpenWordbook(true);
                    }}
                  >
                    Vocabulary Suggestion
                  </Button>
                  {detailQuestion?.data?.backgroundKnowledge ? (
                    <BackgroundKnowledgeDetail detail={detailQuestion?.data} />
                  ) : null}
                </Box>
              }
            >
              <Box
                sx={{
                  scrollbarWidth: "none" /* Firefox */,
                  msOverflowStyle: "none" /* IE and Edge */,
                  "& ::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {categorySelected && (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        fontSize: "12px",
                        color: "#6A6969",
                        cursor: "pointer",
                        ...(aspectViewType === EAspectView.answer_by_aspect
                          ? {
                              color: "#2A9D8F",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setAspectViewType(EAspectView.answer_by_aspect);
                      }}
                    >
                      <QuestionAnswerIcon
                        fontSize="small"
                        sx={{
                          marginRight: "8px",
                        }}
                      />
                      Answer By Aspect
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        fontSize: "12px",
                        color: "#6A6969",
                        cursor: "pointer",
                        ...(aspectViewType === EAspectView.sugesstion_by_aspect
                          ? {
                              color: "#2A9D8F",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setAspectViewType(EAspectView.sugesstion_by_aspect);
                      }}
                    >
                      <AssistantIcon
                        fontSize="small"
                        sx={{
                          marginRight: "8px",
                        }}
                      />
                      Suggestion By Aspect
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    height: "calc(100vh - 400px)",
                    overflowY: "auto",
                    paddingBottom: "16px",
                  }}
                >
                  {categorySelected && aspectViewType === EAspectView.answer_by_aspect && (
                    <AnswerAspectToIdea
                      aspects={sortItemOrder(listAspectPage.data)}
                      question={extractContent(detailQuestion.data?.name ?? "")}
                    />
                  )}
                  {categorySelected && aspectViewType === EAspectView.sugesstion_by_aspect && (
                    <>
                      {categorySelected && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            margin: "10px 0",
                          }}
                        >
                          Choose aspects & get ideas of "{extractContent(categorySelected?.name ?? "")}"
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "-5px",
                        }}
                      >
                        {sortItemOrder(listAspectPage.data)?.map((aspect, idx: number) => {
                          return (
                            <Box
                              key={idx}
                              sx={{
                                border: "1px solid #C6C1C1",
                                color: "#313131",
                                display: "flex",
                                padding: "10px 20px",
                                margin: "5px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                "&:hover": {
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  color: theme.palette.primary.main,
                                  "& .title": {
                                    fontWeight: "bold",
                                  },
                                  "& .nb_items": {
                                    color: theme.palette.primary.main,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                  },
                                },
                                ...(aspectSelectedId === aspect._id
                                  ? {
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      color: theme.palette.primary.main,
                                      "& .title": {
                                        fontWeight: "bold",
                                      },
                                      "& .nb_items": {
                                        color: theme.palette.primary.main,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                      },
                                    }
                                  : {}),
                              }}
                              onClick={() => {
                                setAspectSelectedId(aspect._id);
                              }}
                            >
                              <Box className="title" dangerouslySetInnerHTML={{ __html: aspect.name || "" }} />
                              <Box
                                className="nb_items"
                                sx={{
                                  color: "#6A6969",
                                  border: "1px solid #6A6969",
                                  borderRadius: "50%",
                                  padding: "0 5px",
                                  minHeight: "24px",
                                  minWidth: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  marginLeft: "10px",
                                }}
                              >
                                {aspect.countIdeasByAspect ?? 0}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      {aspectSelectedId && <ListSuggestionAspect aspectId={aspectSelectedId} />}
                    </>
                  )}
                </Box>
              </Box>
            </LayoutTab>
          </Box>
        </Panel>

        {openWordbook && (
          <>
            <Panel
              defaultSize={50}
              style={{
                // width: "360px",
                minWidth: "320px",
              }}
            >
              <LayoutTab
                listTab={[]}
                breadcrumbs={[
                  {
                    text: "Learn",
                    link: ROUTES.LEARN,
                  },
                  {
                    text: `Topic & Question`,
                    link: parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
                      "type-question": typeQuestion,
                    }),
                  },
                  {
                    text: `Ideas`,
                  },
                ]}
                title={extractContent(detailQuestion.data?.name ?? "")}
                subTitle=""
                titleAction={
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "14px",
                      textTransform: "unset",
                    }}
                    onClick={() => {
                      setOpenWordbook(false);
                    }}
                  >
                    Close wordbook
                  </Button>
                }
              >
                <WordbookQuestion />
              </LayoutTab>
            </Panel>
          </>
        )}
        <PanelResizeHandle
          style={{
            width: "5px",
            backgroundColor: "#e1e1e1",
          }}
        />
        <Panel
          defaultSize={50}
          minSize={50}
          style={{
            // width: "360px",
            minWidth: "320px",
          }}
        >
          <MyIdeaPath />
        </Panel>
      </PanelGroup>
    </Box>
  );
};

export default LearnQuestionPage;
