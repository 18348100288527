import { Breadcrumbs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  text: string;
  link?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-ol": {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    gap: 0,
  },
  "& .MuiBreadcrumbs-li": {
    display: "flex",
    margin: 0,
    position: "relative",
    height: 40,
    backgroundColor: theme.palette.primary.main,
    "&:first-of-type": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      "& a": {
        color: theme.palette.primary.contrastText,
        "&::after": {
          borderLeft: `20px solid ${theme.palette.primary.main}`,
        },
      },
    },
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    "& a": {
      color: theme.palette.primary.contrastText,
      "&::after": {
        borderLeft: `20px solid ${theme.palette.primary.main}`,
      },
    },
  },
  "& .MuiBreadcrumbs-separator": {
    display: "none",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 15px 0 30px",
  textDecoration: "none",
  position: "relative",
  height: "100%",
  color: "white",
  fontSize: "14px",
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    borderLeft: `20px solid ${theme.palette.background.default}`,
  },
  "&.first": {
    "&::before": {
      border: "none",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    right: -20,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    borderLeft: `20px solid ${theme.palette.grey[200]}`,
    zIndex: 1,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 15px 0 30px",
  height: "100%",
  backgroundColor: theme.palette.grey[200],
  position: "relative",
  fontSize: "14px",
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    borderLeft: `20px solid ${theme.palette.background.default}`,
  },
}));

const DynamicBreadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <StyledBreadcrumbs separator={<></>}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        if (isLast) {
          return (
            <div key={item.text}>
              <StyledTypography color="text.secondary">{item.text}</StyledTypography>
            </div>
          );
        }

        return (
          <div key={item.text}>
            <StyledLink color="inherit" to={item.link || "#"} className={index === 0 ? "first" : ""}>
              {item.text}
            </StyledLink>
          </div>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default DynamicBreadcrumbs;
