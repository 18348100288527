import { IItems } from "@/common";
import { useUpdateItem } from "@/services-react-query/queries";
import { getDetailItem } from "@/store-redux";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SunEditor from "suneditor-react";

interface IModalPromptStructureProps {
  detail: IItems;
  refetchQuestionPart2?: () => void;
}

function BackgroundKnowledgeModal({ detail, refetchQuestionPart2 }: IModalPromptStructureProps) {
  const [inputValue, setInputValue] = useState("");
  const [showBackgroundKnowledge, setShowBackgroundKnowledge] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (showBackgroundKnowledge && detail?.backgroundKnowledge) {
      setInputValue(detail.backgroundKnowledge);
    }
  }, [showBackgroundKnowledge]);

  const handleClose = () => {
    setShowBackgroundKnowledge(false);
  };

  const { mutate: updateItem, isLoading: isLoadingUpdate } = useUpdateItem({
    onSuccess: () => {
      handleClose();
      if (refetchQuestionPart2) {
        refetchQuestionPart2();
      } else {
        dispatch(getDetailItem(`${detail._id}`));
      }
    },
  });

  return (
    <>
      <Button
        variant="outlined"
        color="info"
        sx={{
          fontSize: "14px",
          padding: "10px",
          textTransform: "none",
        }}
        onClick={() => {
          setShowBackgroundKnowledge(true);
        }}
      >
        Background knowledge
      </Button>
      <Dialog
        open={showBackgroundKnowledge}
        onClose={handleClose}
        PaperProps={
          {
            // sx: {
            //   minWidth: "80vw",
            //   minHeight: "60vw",
            // },
          }
        }
        fullScreen
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
          }}
        >
          Please fill background knowledge
        </DialogTitle>
        <DialogContent
          sx={{
            "& .sun-editor": {
              height: "calc(80vh - 32px)",
              "& .se-container": {
                height: "calc(80vh - 32px)",
                "& .se-wrapper": {
                  height: "calc(80vh - 82px)",
                  overflowY: "auto",
                },
              },
            },
          }}
        >
          <SunEditor onChange={(content) => setInputValue(content)} defaultValue={detail?.backgroundKnowledge || ""} />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            gap: "8px",
            "& button": {
              textTransform: "none",
              fontSize: "16px",
            },
          }}
        >
          <Button
            onClick={() => {
              inputValue &&
                updateItem({
                  ...detail,
                  description: detail.description || "",
                  id: detail._id,
                  backgroundKnowledge: inputValue,
                });
            }}
            color="secondary"
            disabled={!inputValue || isLoadingUpdate}
            variant="contained"
          >
            {isLoadingUpdate ? <CircularProgress size={24} /> : "Save"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BackgroundKnowledgeModal;
