import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { onPlayReadStr } from "@/helpers";
import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CorrectSound from "./../../../../assets/correct.mp3";
import WrongSound from "./../../../../assets/wrong.mp3";
import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import WriteWord from "./WriteWord";
interface LearnVocaProps {
  items: any[];
  learnTypes: LearnVocaTypeEnum[];
}

export enum LearnVocaTypeEnum {
  match_word_to_meaning = "match_word_to_meaning", // chọn từ phù hợp với nghĩa
  match_meaning_to_word = "match_meaning_to_word", // chọn nghĩa phù hợp với từ
  match_meaning_to_pronunciation = "match_meaning_to_pronunciation", // chọn nghĩa phù hợp với phát âm
  match_word_to_pronunication = "match_word_to_pronunication", // chọn từ phù hợp với phát âm
  match_pronunciation_to_word = "match_pronunciation_to_word", // chọn phát âm phù hợp với từ
  match_pronunciation_to_meaning = "match_pronunciation_to_meaning", // chọn phát âm phù hợp với nghĩa
  write_word_to_meaning = "write_word_to_meaning", // viết chữ phù hợp với nghĩa
  write_word_to_pronunciation = "write_word_to_pronunciation", // viết chữ phù hợp với phát âm
}

export const learnTypeOptions = [
  LearnVocaTypeEnum.match_word_to_meaning,
  LearnVocaTypeEnum.match_meaning_to_word,
  LearnVocaTypeEnum.match_meaning_to_pronunciation,
  LearnVocaTypeEnum.match_word_to_pronunication,
  LearnVocaTypeEnum.match_pronunciation_to_word,
  LearnVocaTypeEnum.match_pronunciation_to_meaning,
  LearnVocaTypeEnum.write_word_to_meaning,
  LearnVocaTypeEnum.write_word_to_pronunciation,
];

export const labelLearnType = {
  [LearnVocaTypeEnum.match_word_to_meaning]: "chọn từ phù hợp với nghĩa",
  [LearnVocaTypeEnum.match_meaning_to_word]: "chọn nghĩa phù hợp với từ",
  [LearnVocaTypeEnum.match_meaning_to_pronunciation]: "chọn nghĩa phù hợp với phát âm",
  [LearnVocaTypeEnum.match_word_to_pronunication]: "chọn từ phù hợp với phát âm",
  [LearnVocaTypeEnum.match_pronunciation_to_word]: "chọn phát âm phù hợp với từ",
  [LearnVocaTypeEnum.match_pronunciation_to_meaning]: "chọn phát âm phù hợp với nghĩa",
  [LearnVocaTypeEnum.write_word_to_meaning]: "viết chữ phù hợp với nghĩa",
  [LearnVocaTypeEnum.write_word_to_pronunciation]: "viết chữ phù hợp với phát âm",
};
type LearnTypeOptionsObject = {
  [key in LearnVocaTypeEnum]: boolean;
};
const LearnVoca = ({ items, learnTypes }: LearnVocaProps) => {
  const theme = useTheme();

  const learnTypeOptionsObject: LearnTypeOptionsObject = learnTypes.reduce((acc, type) => {
    acc[type] = false;
    return acc;
  }, {} as LearnTypeOptionsObject);

  const generateUID = (length = 10) => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < length; i++) {
      uid += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return uid;
  };
  const [words, setWords] = useState(
    items.map((item, index) => ({
      ...(item.term
        ? {
            ...(item.word ?? { _id: `${index}_${generateUID(10)}` }),
            word: item.term,
            content: [
              {
                type: "define",
                text: item.definition,
              },
            ],
          }
        : { ...item.word }),
      learnType: learnTypeOptionsObject,
    }))
  );
  console.log("words", words);
  const [randomWords, setRandomWords] = useState([]);
  const [done, setDone] = useState(false);
  const [currentWord, setCurrentWord] = useState<{ data: any; learnType: any }>({ data: null, learnType: null });
  const [answer, setAnswer] = useState(null);
  const [isWriteDone, setIsWriteDone] = useState(false);
  const getRandomWords = async () => {
    if (!items.length) return;
    try {
      const dataRes = await Requests.get(
        getApiUrl(
          `${ENDPOINTS.DICTS_GET_RANDOM_WORDS}?count=${items.length > 20 ? items.length : 20}&excludeIds=${items
            .map((item) => item?.word?._id)
            .join(",")}`
        )
      );
      if (dataRes.length) {
        setRandomWords(dataRes);
      }
    } catch (e) {}
  };
  useEffect(() => {
    getRandomWords();
    // getRandomCurrentWord();
  }, []);

  useEffect(() => {
    if (answer) {
      const isCorrect = answer?._id === currentWord?.data?._id;
      const audio = new Audio(isCorrect ? CorrectSound : WrongSound);
      audio.play();
      setWords((prev) =>
        prev.map((item) => {
          if (isCorrect && currentWord?.data?._id === item._id) {
            return {
              ...item,
              learnType: {
                ...item.learnType,
                [currentWord.learnType]: true,
              },
            };
          }
          return item;
        })
      );
    }
  }, answer);

  useEffect(() => {
    const wordsWithFalseLearnType = words.filter((word) => Object.values(word.learnType).includes(false));
    const randomWord = wordsWithFalseLearnType[Math.floor(Math.random() * wordsWithFalseLearnType.length)];
    const falseKeys = randomWord?.learnType
      ? Object.keys(randomWord?.learnType).filter((key) => randomWord.learnType[key] === false)
      : [];
    const randomKey = falseKeys[Math.floor(Math.random() * falseKeys.length)];
    const data = {
      data: randomWord,
      learnType: randomKey,
    };

    setTimeout(() => {
      if (randomWord) {
        getRandomCurrentWord(data);
      } else {
        getRandomCurrentWord(null);
      }
    }, 1000);
  }, [words]);

  const getRandomCurrentWord = (data: any) => {
    setAnswer(null);
    setIsWriteDone(false);
    if (data) {
      setCurrentWord(data);
    } else {
      setCurrentWord({
        data: null,
        learnType: null,
      });
      setDone(true);
    }
  };
  function getRandomItems(items: any[], requiredId: string) {
    const requiredItem = items.find((item: any) => item?._id === requiredId);
    if (!requiredItem) {
      return [];
    }
    const remainingItems = items.filter((item: any) => item?._id !== requiredId);
    const shuffled = remainingItems.sort(() => 0.5 - Math.random());
    const randomWords = shuffled.slice(0, 3);
    return [requiredItem, ...randomWords].sort(() => 0.5 - Math.random());
  }

  const onChoiceAnswer = (word: any) => {
    if (answer) return;
    setAnswer(word);
  };

  const wordsQuestion = useMemo(() => {
    if (!currentWord || !randomWords.length) return [];
    const data = [...items.map((item) => item.word), ...randomWords];
    return getRandomItems(data, currentWord.data?._id);
  }, [randomWords, currentWord]);
  console.log("wordsQuestion", wordsQuestion);

  useEffect(() => {
    if (
      currentWord.learnType === LearnVocaTypeEnum.match_word_to_pronunication ||
      currentWord.learnType === LearnVocaTypeEnum.match_meaning_to_pronunciation ||
      currentWord.learnType === LearnVocaTypeEnum.write_word_to_pronunciation
    ) {
      onPlayReadStr(currentWord?.data?.word);
    }
  }, [currentWord]);

  const meaningCurrentWord = currentWord?.data?.content?.find((content: any) => content?.type === "define")?.text;

  const totalQuestion = words.length * learnTypes.length;
  const correctQuestion = useMemo(() => {
    return words.reduce((prevVal, val) => {
      let nbCorrect = 0;
      Object.values(val.learnType).forEach((isCorrectLearnType) => {
        if (isCorrectLearnType) nbCorrect += 1;
      });
      return prevVal + nbCorrect;
    }, 0);
  }, [words]);
  // console.log("words", words);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "800px",
          maxWidth: "calc(100vw - 40px)",
          maxHeight: "calc(100vh - 80px)",
          backgroundColor: "#F3F3FF",
          borderRadius: "16px",
          padding: "16px 32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {done ? (
          <>
            <DoneOutlineIcon
              sx={{
                fontSize: "60px",
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Congratulations!
            </Typography>
            <Typography>You have completed learning the vocabulary</Typography>
          </>
        ) : wordsQuestion.length ? (
          <>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              {labelLearnType[currentWord?.learnType] ?? ""}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                "& p": {
                  fontSize: "16px",
                  color: theme.palette.primary.main,
                },
              }}
            >
              {currentWord?.learnType === LearnVocaTypeEnum.match_word_to_meaning ? (
                <Typography>{meaningCurrentWord}</Typography>
              ) : currentWord?.learnType === LearnVocaTypeEnum.match_meaning_to_word ? (
                <Typography>{currentWord?.data?.word}</Typography>
              ) : currentWord?.learnType === LearnVocaTypeEnum.match_meaning_to_pronunciation ? (
                <IconButton
                  onClick={() => {
                    onPlayReadStr(currentWord?.data?.word);
                  }}
                >
                  <VolumeUpIcon />
                </IconButton>
              ) : currentWord?.learnType === LearnVocaTypeEnum.match_word_to_pronunication ||
                currentWord?.learnType === LearnVocaTypeEnum.write_word_to_pronunciation ? (
                <IconButton
                  onClick={() => {
                    onPlayReadStr(currentWord?.data?.word);
                  }}
                >
                  <VolumeUpIcon />
                </IconButton>
              ) : currentWord?.learnType === LearnVocaTypeEnum.match_pronunciation_to_word ? (
                <Typography>{currentWord?.data?.word}</Typography>
              ) : currentWord?.learnType === LearnVocaTypeEnum.match_pronunciation_to_meaning ||
                currentWord?.learnType === LearnVocaTypeEnum.write_word_to_meaning ? (
                <Typography>{meaningCurrentWord}</Typography>
              ) : (
                <></>
              )}
            </Box>
            {currentWord?.learnType === LearnVocaTypeEnum.write_word_to_meaning ||
            currentWord?.learnType === LearnVocaTypeEnum.write_word_to_pronunciation ? (
              <WriteWord
                currentWord={currentWord}
                onResponse={(isCorrect) => {
                  const audio = new Audio(isCorrect ? CorrectSound : WrongSound);
                  audio.play();
                  setIsWriteDone(true);
                  setWords((prev) =>
                    prev.map((item) => {
                      if (isCorrect && currentWord?.data?._id === item._id) {
                        return {
                          ...item,
                          learnType: {
                            ...item.learnType,
                            [currentWord.learnType]: true,
                          },
                        };
                      }
                      return item;
                    })
                  );
                }}
              />
            ) : (
              <>
                {wordsQuestion.map((word, index) => {
                  const meaningText = word.content?.find((content: any) => content?.type === "define")?.text;
                  const isCorrect = answer && word?._id === currentWord?.data?._id;
                  const isNotCorrect = answer && answer?._id !== currentWord?.data?._id && answer?._id === word._id;
                  const styleItem = {
                    margin: "8px 0",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    border: "1px solid #A5BFCC",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "60px",
                    ...(answer ? {} : { cursor: "pointer" }),
                    ...(isCorrect ? { border: "2px solid #5CB338" } : {}),
                    ...(isNotCorrect ? { border: "2px solid #FB4141" } : {}),
                    "& span": {
                      fontWeight: "800",
                      fontSize: "14px",
                      marginRight: "16px",
                    },
                  };
                  switch (currentWord?.learnType) {
                    case LearnVocaTypeEnum.match_word_to_meaning:
                      return (
                        <Box
                          onClick={() => onChoiceAnswer(word)}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          {word.word}
                        </Box>
                      );
                    case LearnVocaTypeEnum.match_meaning_to_word:
                      return (
                        <Box
                          onClick={() => onChoiceAnswer(word)}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          {meaningText}
                        </Box>
                      );
                    case LearnVocaTypeEnum.match_meaning_to_pronunciation:
                      return (
                        <Box
                          onClick={() => onChoiceAnswer(word)}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          {meaningText}
                        </Box>
                      );
                    case LearnVocaTypeEnum.match_word_to_pronunication:
                      return (
                        <Box
                          onClick={() => onChoiceAnswer(word)}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          {word?.word}
                        </Box>
                      );
                    case LearnVocaTypeEnum.match_pronunciation_to_word:
                      return (
                        <Box
                          onClick={() => {
                            onChoiceAnswer(word);
                          }}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          <Box
                            sx={{
                              flex: 1,
                            }}
                          ></Box>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              onPlayReadStr(word?.word);
                            }}
                          >
                            <VolumeUpIcon />
                          </IconButton>
                        </Box>
                      );
                    case LearnVocaTypeEnum.match_pronunciation_to_meaning:
                      return (
                        <Box
                          onClick={() => {
                            onChoiceAnswer(word);
                          }}
                          sx={{
                            ...styleItem,
                          }}
                        >
                          <span>{index + 1}.</span>
                          <Box
                            sx={{
                              flex: 1,
                            }}
                          ></Box>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              onPlayReadStr(word?.word);
                            }}
                          >
                            <VolumeUpIcon />
                          </IconButton>
                        </Box>
                      );
                    default:
                      return <></>;
                  }
                })}
              </>
            )}

            {(answer || isWriteDone) && (
              <Box
                sx={{
                  overflowY: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {currentWord?.data.word}
                </Typography>
                <WordDetail word={currentWord?.data} />
              </Box>
            )}
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                textAlign: "right",
                marginTop: "16px",
              }}
            >
              {correctQuestion}/{totalQuestion}
            </Typography>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={240}
                height={16}
                sx={{
                  margin: "8px 0",
                  borderRadius: "8px",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={320}
                height={32}
                sx={{
                  margin: "8px 0",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{
                margin: "8px 0",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{
                margin: "8px 0",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{
                margin: "8px 0",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{
                margin: "8px 0",
                borderRadius: "8px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
export default LearnVoca;
