export enum ETypeItem {
  "topic" = "topic",
  "question" = "question",
  "category" = "category",
  "aspect" = "aspect",
  "idea.main" = "idea.main",
  "idea.structure" = "idea.structure",
  "idea.grammar" = "idea.grammar",
  "level" = "level",
  "group.question.part2" = "group.question.part2",
  "question.part2" = "question.part2",
  "question.category" = "question.category",
  "question.type" = "question.type",
  "guided.question" = "guided.question",
}

export enum AIModels {
  "gpt-3.5-turbo-0125" = "gpt-3.5-turbo-0125",
  "gpt-4-0125-preview" = "gpt-4-0125-preview",
  "gpt-4-turbo-preview" = "gpt-4-turbo-preview",
  "gpt-4-1106-preview" = "gpt-4-1106-preview",
  "claude-3-5-sonnet-20240620" = "claude-3-5-sonnet-20240620",
  "claude-3-opus-20240229" = "claude-3-opus-20240229",
}

export enum ELevel {
  "level1" = "level1",
  "level2" = "level2",
  "level3" = "level3",
  "level4" = "level4",
  "level5" = "level5",
}

export interface IItemUpdate extends IItem {
  id: string;
}

export interface IItem {
  name: string;
  description?: string;
  type: ETypeItem;
  parentId: string | null;
  aspectId?: string;
  order?: number;
  level?: ELevel;
  public?: boolean;
  topicId?: string;
  imageUrl?: string;
  files?: File;
  file?: File;
  questionTypeId?: string | null;
  backgroundKnowledge?: string | null;
}

export interface IGenerateIdeas {
  questionId: string;
  aspectId: string;
  prompt: string;
  creativity: number;
  aiModel: AIModels;
  numberOfIdeas: number;
  question: string;
}

export interface IGenerateGrammarStructure {
  questionId: string;
  aspectId: string;
  prompt?: string;
}

export interface IRemoveMultipleItems {
  items: string[];
}

export interface ISaveCustomAnswer {
  questionId?: string;
  question: string;
  answer: string;
  idea: string | null;
  boldIndex: number[] | null;
  id?: string;
  topicId?: string;
}

export interface IAnswerItem {
  _id: string;
  questionId: string;
  question: string;
  answer: string;
  idea: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  topicId?: string;
}

export interface ICreateItem {
  items: IItem[];
}

export interface IMyIdeaPathState {
  data: IItems[];
}

export interface IEditCustomAnswerState {
  data: string;
}

export interface IItems {
  aspectId: string;
  _id: string;
  name: string;
  childrenCount: number;
  description: string | null;
  createdBy: string;
  type: ETypeItem;
  parentId: string | null;
  createdAt: string;
  updatedAt: string;
  order: number;
  level?: ELevel;
  public?: boolean;
  locationIdea?: string;
  topicId?: string;
  countIdeasByAspect: number;
  imageUrl?: string;
  score: number;
  genByAI?: boolean;
  backgroundKnowledge?: string | null;
}

export interface IGetList {
  skip: number;
  limit: number;
  sortBy?: string;
  sortType?: string;
  searchQuery?: string;
  parentId?: string | null;
  type?: ETypeItem;
  aspectId?: string;
  questionId?: string;
  questionTypeId?: string | null;
  checkMatchScore?: boolean;
  isWithGuidedQuestion?: boolean;
  isOnlyPublic?: boolean;
}

export interface IGetListAllAspectsState {
  data: IGetList[];
}

export interface IIdeaCombineCategoryAspect {
  _id: string;
  name: string;
  contentIdea: string;
}

export enum EAction {
  delete = "delete",
  edit = "edit",
}

export interface IDetailItem {
  id: string;
}

export interface ItemsSort extends IItems {
  id: string;
}
