import { extractContent } from "@/helpers";
import { getDetailItem, IApplicationState } from "@/store-redux";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import WordbankCMSPage from "../WordbankCMSPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ROUTES } from "@/constants";

const QuestionWordbookCMSPage = () => {
  const { questionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  useEffect(() => {
    if (questionId && detailQuestion.data?._id !== questionId) {
      dispatch(getDetailItem(questionId));
    }
  }, []);
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            minWidth: "68px",
            height: "40px",
            padding: "6px 16px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
            marginRight: "16px",
            "& svg": {
              marginRight: "4px",
            },
          }}
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {extractContent(detailQuestion?.data?.name ?? "")}
        </Typography>
      </Box>
      <WordbankCMSPage questionId={questionId} />
    </Box>
  );
};
export default QuestionWordbookCMSPage;
