import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useState } from "react";
import LearnVoca, { labelLearnType, learnTypeOptions, LearnVocaTypeEnum } from ".";

const PrepareLearn = ({ items }: { items: any }) => {
  console.log("items", items);
  const theme = useTheme();

  const [choiceIds, setChoiceIds] = useState(items.map((item: any) => item._id));
  const [choiceLearnTypes, setChoiceLearnTypes] = useState<LearnVocaTypeEnum[]>(learnTypeOptions);
  const [isShowLearnVoca, setIsShowLearnVoca] = useState(false);
  const onContinue = () => {
    setIsShowLearnVoca(true);
  };
  if (isShowLearnVoca) {
    return <LearnVoca items={items.filter((item: any) => choiceIds.includes(item._id))} learnTypes={choiceLearnTypes}/>;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flex: 1,
          padding: "8px 16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            "& span": {
              fontWeight: "400",
              marginLeft: "4px",
              fontStyle: "italic",
            },
          }}
        >
          Chọn từ
          <span>({choiceIds.length} từ)</span>
        </Typography>
        <Box
          sx={{
            maxHeight: "calc(100vh - 120px)",
            overflowY: "auto",
          }}
        >
          {items.map((item: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: "4px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "0.5px solid",
                  borderColor: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setChoiceIds((prev: any) =>
                    prev?.includes(item._id) ? prev?.filter((itemP: string) => itemP !== item._id) : [...prev, item._id]
                  );
                }}
              >
                <IconButton sx={{ color: theme.palette.primary.main }}>
                  {choiceIds?.includes(item._id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {item.term ?? item.word?.word ?? ""}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "8px 16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            "& span": {
              fontWeight: "400",
              marginLeft: "4px",
              fontStyle: "italic",
            },
          }}
        >
          Chọn loại bài tập
          <span>({choiceLearnTypes.length} loại)</span>
        </Typography>

        <Box
          sx={{
            maxHeight: "calc(100vh - 120px)",
            overflowY: "auto",
          }}
        >
          {learnTypeOptions.map((item: LearnVocaTypeEnum, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: "4px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "0.5px solid",
                  borderColor: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setChoiceLearnTypes((prev: any) =>
                    prev?.includes(item) ? prev?.filter((itemP: string) => itemP !== item) : [...prev, item]
                  );
                }}
              >
                <IconButton sx={{ color: theme.palette.primary.main }}>
                  {choiceLearnTypes?.includes(item) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
                <Typography sx={{}}>{labelLearnType[item]}</Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px 0",
          }}
        >
          <Button
            onClick={() => {
              onContinue();
            }}
            variant="contained"
            sx={{
              textTransform: "unset",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PrepareLearn;
