import { forEach } from "lodash";
import configs from "@/configs.json";

export const ENDPOINTS = {
  POST_LOGIN: "/auth/login",
  POST_REGISTER: "/auth/register",
  ONE_AUTH: "/auth/:id",
  GET_CURRENT_USER: "/auth/profile",
  CREATE_MULTIPLE_USERS: "/auth/registerMultiple",
  GET_LIST_USER: "/auth/list",
  GET_LIST_ITEMS: "/items",
  CREATE_MULTIPLE_ITEMS: "/items/bulk",
  ONE_ITEM: "/items/:id",
  GENERATE_IDEAS: "/items/generateIdeas",
  GENERATE_GRAMMARS: "/items/generateGrammars",
  GENERATE_STRUCTURES: "/items/generateStructures",
  CUSTOM_ANSWER: "/answers",
  ONE_ITEM_CUSTOM_ANSWER: "/answers/:id",
  REMOVE_MULTIPLE_ITEMS: "/items/removeMultiples",
  GENERATE_CONNECTOR: "/answers/generate-connector",
  GET_QUOTA: "/answers/generate-connector/quota",
  GET_QUOTA_PRACTICE: "/practices/quota",
  REPHARSE_ANSWER: "/answers/repharse-answer",
  EDIT_INFO_USER: "/auth/profile/update",
  CHANGE_STATUS_USER: "/auth/change-status/:id",
  POST_CHALLENGES_UPLOAD: "/challenges/upload",
  POST_CHALLENGES_TRANSCRIBE: "/challenges/:id/transcribe",
  POST_CHALLENGES_EVALUATE: "/challenges/:id/evaluate",
  POST_PRACTICES_UPLOAD: "/practices/upload",
  POST_PRACTICES_TRANSCRIBE: "/practices/:id/transcribe",
  POST_PRACTICES_EVALUATE: "/practices/:id/evaluate",
  POST_WORD_MATCH_EVALUATE: "/practices/:id/word-match",
  GET_WORD_SEARCH: "/dicts/search",
  CREATE_WORDBOOK: "/wordbooks",
  GET_WORDBOOKS: "/wordbooks",
  DELETE_WORDBOOK: "/wordbooks/:id",
  EDIT_WORDBOOK: "/wordbooks/:id",
  GET_ONE_WORDBOOK: "/wordbooks/:id",
  SAVE_WORD: "/vocabs",
  GET_VOCABS_BY_WORDBOOK: "/vocabs/wordbook/:wordbookId",
  DELETE_VOCAB: "/vocabs/:id",
  GENERATE_VOCABULARY_PRACTICE: "/vocabs/generatePractice",
  GET_MY_PRACTICE: "/practices/my-practice",
  UPLOAD_FILES: "/files/upload",
  SAVE_PERSONALIZED_CMS: "/files/savePersonalizedCMS",
  GET_PERSONALIZED_FILE: "/uploads/personalized.json",
  SAVE_STRUCTURE_CMS: "/files/saveStructureCMS",
  GET_STRUCTURE_CMS: "/cms/structure.json",
  WORD_LIMIT_ANSWER: "/answers/word-limit-answer",
  GET_SUGGEST_VOCA_BY_BAND: "/answers/suggest-voca-by-band",
  BACKOFFICE_SAVE_BY_CODE: "/backoffice/:code",
  BACKOFFICE_GET_BY_CODE: "/backoffice/:code",
  GET_HELPS: "/helpers",
  GRAMMAR_ANALYSIS: "/answers/grammar-analysis",
  GET_GRAMMAR_BY_QUESTION: "/grammars/question/:questionId",
  GRAMMAR_GENERATE: "/grammars/generate",
  GRAMMAR_SAVE: "/grammars/createOrUpdate",
  GRAMMAR_ANALYZE_SENTENCE: "/grammars/analyzeGrammarSentence",
  GRAMMAR_GENERATE_PRACTICE: "/grammars/generateGrammarPractice",
  GET_REMAINING_ENERGY: "/energy-log/remaining",
  ADD_INVOICE_ENERGY: "/energy-invoice/add",
  GRAMMAR_SAVED_ADD: "/grammar-saved",
  GRAMMAR_SAVED_LIST: "/grammar-saved",
  CLEANING_IDEAS: "/items/cleaningIdeas",
  DICT_REPORT: "/dicts/:id/report",
  LIST_DICT_REPORT: "/dicts/reported",
  GEN_DICT: "/dicts/gen",
  UPDATE_DICT: "/dicts/:id",
  GEN_GUIDED_QUESTIONS: "/items/genGuidedQuestions",
  GET_QUIDED_QUESTIONS: "/items/getGuidedByQuestion/:questionId",
  SUGGEST_ANSWER_GUIDED_QUESTION: "/items/suggestAnswerGuidedQuestion",
  SUGGEST_ANSWER_PART_TWO: "/items/suggestAnswerPartTwo",
  ADD_CONNECTOR_ANSWER_PART_TWO: "/items/addConnectorAnswerPartTwo",
  GENERATE_IDEAS_BY_ASPECTS: "/items/generateIdeasByAspects",
  DELETE_GUIDED_QUESTIONS: "/items/deleteGuidedQuestion",
  KEYWORD_TO_IDEA: "/items/keywordToIdea",
  KEYWORD_TO_IDEA_PART_2: "/items/keywordToIdeaPart2",
  // teacher
  // class module
  GET_CLASS_BY_TEACHER: "/class/by-teacher",
  CREATE_CLASS: "/class/create",
  GET_ONE_CLASS: "/class/get-one/:classId",
  JOIN_CLASS: "/class/join",
  STUDENT_GET_LIST_MY_CLASS: "/class/student/my-class",

  // lesson module
  CREATE_LESSON: "/lesson/create",
  TAKE_ATTENDANCE: "/lesson/:lessonId/take-attendance",
  GET_LESSONS_BY_CLASS: "/lesson/class/:id",

  // homework
  SUBMIT_HOMEWORK: "/homework/submit",
  GET_HOMEWORK_BY_LESSON: "/homework/lesson/:id",
  FREESTYLE_TO_IDEA: "/items/freestyleToIdea",
  FREELEARN_TO_IDEA: "/items/freeLearnToIdea",
  GRAMMAR_VOCABULARY_PRACTICE: "/items/grammarVocabularyPratice",
  CHAT_WITH_AI: "/items/chatWithAI",

  GEN_VOCA_BY_BAND: "/voca-question/gen-voca-by-band",
  SAVE_VOCA_QUESTION: "/voca-question/upsert",
  GET_VOCA_QUESTION: "/voca-question/:questionId",

  PRACTICE_GET_MAIN_IDEA: "/practices/get-main-idea",

  DICTS_GET_RANDOM_WORDS: "/dicts/random",
  INSERT_TERMS_WORDBOOK: "/wordbooks/enter-terms/:wordbookId",
  GET_COMMUNITY_WORDBOOKS: "/wordbooks/community/get-all",
};

export const getApiUrl = (endpoint: string, params?: any, isParamId: boolean = false): string => {
  let path = endpoint;
  if (params) {
    if (isParamId) {
      forEach(params, (value, key) => {
        const replace = `:${key}`;
        if (path.indexOf(replace) !== -1) {
          const re = new RegExp(replace, "g");
          path = path.replace(re, value.toString());
        }
      });
    } else {
      path = path + "?" + new URLSearchParams(params).toString();
    }
  }
  const baseApi = import.meta.env.VITE_API_BASE || configs.apiBaseDev;
  return baseApi + path;
};

export const parseParamUrl = (endpoint: string, params?: any, query?: any): string => {
  let path = endpoint;
  if (params) {
    forEach(params, (value, key) => {
      const replace = `:${key}`;
      if (path.indexOf(replace) !== -1) {
        const re = new RegExp(replace, "g");
        path = path.replace(re, value.toString());
      }
    });
  }
  if (query) {
    path = path + "?" + new URLSearchParams(query).toString();
  }
  return path;
};
