import { Box, Button, Input } from "@mui/material";
import { useEffect, useState } from "react";

const WriteWord = ({ currentWord, onResponse }: { currentWord: any; onResponse: (check: boolean) => void }) => {
  const [value, setValue] = useState("");
  const [isChecked, setIsChecked] = useState<any>(undefined);
  useEffect(() => {
    setValue("");
    setIsChecked(undefined);
  }, [currentWord]);
  const onCheck = () => {
    if (isChecked !== undefined) return;
    if (value.trim() === (currentWord?.data?.word ?? "").toString().toLowerCase()) {
      onResponse(true);
      setIsChecked(true);
    } else {
      onResponse(false);
      setIsChecked(false);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Input
        value={value}
        onChange={(event) => setValue(event.target.value)}
        placeholder="Nhập từ phù hợp"
        sx={{
          width: "100%",
          ...(isChecked === true
            ? { borderBottom: "2px solid #5CB338" }
            : isChecked === false
            ? { borderBottom: "2px solid #FB4141" }
            : {}),
        }}
        disabled={isChecked !== undefined}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onCheck();
          }
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 0",
        }}
      >
        <Button
          onClick={() => {
            onCheck();
          }}
          variant="contained"
          sx={{
            textTransform: "unset",
            fontWeight: "bold",
          }}
          disabled={isChecked !== undefined}
        >
          Check
        </Button>
      </Box>
    </Box>
  );
};
export default WriteWord;
