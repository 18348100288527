import { ENDPOINTS, Requests, getApiUrl } from "@/common";
import { IEditWordbook, ISaveWord, IWordbook } from "@/common/types/wordbank";

export const createWordbook = async (payload: IWordbook): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.CREATE_WORDBOOK);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};

export const getListWordbooks = async (questionId?: string): Promise<any> => {
  const apiUrl = getApiUrl(`${ENDPOINTS.GET_WORDBOOKS}${questionId ? `?questionId=${questionId}` : ""}`);
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const deleteWordbook = async (id: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.DELETE_WORDBOOK, { id }, true);
  const data: any = await Requests.delete(apiUrl);
  return id;
};

export const editWordbook = async (payload: IEditWordbook): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.EDIT_WORDBOOK, { id: payload.id }, true);
  const data: any = await Requests.put(apiUrl, payload);
  return data;
};

export const getOneWordbook = async (id: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_ONE_WORDBOOK, { id }, true);
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const saveWord = async (payload: ISaveWord): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.SAVE_WORD);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};

export const getListVocabsByWordbook = async (wordbookId: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_VOCABS_BY_WORDBOOK, { wordbookId }, true);
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const deleteVoca = async (id: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.DELETE_VOCAB, { id }, true);
  const data: any = await Requests.delete(apiUrl);
  return id;
};

export const insertTermsWordbook = async (payload: any): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.INSERT_TERMS_WORDBOOK, { wordbookId: payload.id }, true);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};

export const getListCommunityWordbooks = async (): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_COMMUNITY_WORDBOOKS);
  const data: any = await Requests.get(apiUrl);
  return data;
};
