import WordbookCard from "@/pages/WordbankPage/components/wordbooks/WordbookCard";
import WordbookPage from "@/pages/WordbookPage";
import { useGetListWordbooks } from "@/services-react-query/queries/wordbank.query";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const WordbookQuestion = () => {
  const { questionId } = useParams();

  const dispatch = useDispatch();
  const [wordBooks, setWordBooks] = useState([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [wordbookActive, setWordbookActive] = useState(null);
  const { mutate: getListWordbooks, isLoading: loading } = useGetListWordbooks({
    onSuccess: (data: any) => {
      setWordBooks(data);
    },
  });
  useEffect(() => {
    getListWordbooksFnc();
  }, []);
  const getListWordbooksFnc = () => {
    getListWordbooks(questionId);
  };
  if (wordbookActive) {
    return (
      <Box>
        <WordbookPage
          wordbookId={wordbookActive}
          onBack={() => {
            setWordbookActive(null);
          }}
        />
      </Box>
    );
  }
  return (
    <Box>
      <Grid container>
        {wordBooks.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{
                padding: "10px",
              }}
            >
              <WordbookCard
                item={item}
                onClickItem={() => {
                  setWordbookActive(item._id);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default WordbookQuestion;
