import { useGetListCommunityWordbooks } from "@/services-react-query/queries/wordbank.query";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import WordbookCard from "./wordbooks/WordbookCard";

const ListWordbookCommunity = () => {
  const [communityWordBooks, setCommunityWordBooks] = useState([]);

  const { mutate: getListCommunityWordbooks, isLoading: loading } = useGetListCommunityWordbooks({
    onSuccess: (data: any) => {
      setCommunityWordBooks(data);
    },
  });

  useEffect(() => {
    getListCommunityWordbooks();
  }, []);
  console.log("communityWordBooks", communityWordBooks);
  return (
    <Box>
      <Typography>Wordbook Community</Typography>
      <Grid container>
        {communityWordBooks.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              sx={{
                padding: "10px",
              }}
            >
              <WordbookCard item={item} onlyView={true} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ListWordbookCommunity;
