import { useMutation, useQuery } from "react-query";
import { addMultipleItems, chatWithAI, deleteItem, fetchDetailItem, fetchListItems, updateItem } from "../api";
import { IDetailItem, IGetList, IItems } from "@/common";
import { IQueryOptions } from ".";

interface IGetListItems {
  query: IGetList;
  options?: IQueryOptions;
}

export const useGetListItems = (payload: IGetListItems) => {
  const { query, options = {} } = payload;
  return useQuery(["fetchListItems"], () => fetchListItems(query), {
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetDetailQuestion = (payload: IQueryOptions & IDetailItem) => {
  const { id, ...options } = payload;
  return useQuery(["fetchDetailQuestion"], () => fetchDetailItem({ id }), {
    keepPreviousData: true,
    ...options,
  });
};

export const useGetDetailItem = (payload: IQueryOptions & IDetailItem) => {
  const { id, ...options } = payload;
  return useQuery(["fetchDetailItem"], () => fetchDetailItem({ id }), {
    keepPreviousData: true,
    ...options,
  });
};

export const useGetTopicsAll = (payload: IGetListItems) => {
  const { query, options = {} } = payload;
  return useQuery(["fetchAllTopics"], () => fetchListItems(query), {
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetQuestionsOfTopicAll = (payload: IGetListItems) => {
  const { query, options = {} } = payload;
  return useQuery(["fetchAllQuestionsofTopic"], () => fetchListItems(query), {
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetGroupQuestionPart2All = (payload: IGetListItems) => {
  const { query, options = {} } = payload;
  return useQuery<IItems[]>(["fetchListGroupQuestionPart2All"], () => fetchListItems(query), {
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetQuestionPart2All = (payload: IGetListItems) => {
  const { query, options = {} } = payload;
  return useQuery<IItems[]>(["fetchListQuestionPart2All"], () => fetchListItems(query), {
    keepPreviousData: true,
    ...options,
  });
};

export const useAddMultipleItems = (options: IQueryOptions) => {
  return useMutation(addMultipleItems, options);
};

export const useUpdateItem = (options: IQueryOptions) => {
  return useMutation(updateItem, options);
};

export const useDeleteItem = (options: IQueryOptions) => {
  return useMutation(deleteItem, options);
};

export const useChatWithAI = (options: IQueryOptions) => {
  return useMutation(chatWithAI, options);
};
