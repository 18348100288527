import {
  createItems,
  deleteItem,
  EAction,
  ENDPOINTS,
  ETypeItem,
  getApiUrl,
  getListAspects,
  getListCategories,
  IApplicationState,
  ICreateItem,
  IItems,
  ItemsSort,
  Requests,
  showMessage,
  updateItem,
} from "@/store-redux";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../styles";
import { extractContent, sortItemOrder } from "@/helpers";
import FormGenerateGuidedQ from "./FormGenerateGuidedQ";
import CreateEditItemModal, { IFormDataProps } from "@/components/CreateEditItemModal";
import { MenuAction } from "@/components/MenuAction";
import Swal from "sweetalert2";
import { IHandleAction } from "../interface";
import BackgroundKnowledgeModal from "@/components/BackgroundKnowledgeModal";

interface GenerateQuidedQuestionsProps {
  question: IItems;
  onBack: () => void;
  refetchQuestionPart2: () => void;
}

const GenerateQuidedQuestions: React.FC<GenerateQuidedQuestionsProps> = ({
  question,
  onBack,
  refetchQuestionPart2,
}) => {
  const dispatch = useDispatch();
  const listCategories = useSelector((state: IApplicationState) => state.listCategories);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);
  const [selectedAspectId, setSelectedAspectId] = useState<string | null>(null);
  const createItemsResult = useSelector((state: IApplicationState) => state.createItems);
  const updateItemResult = useSelector((state: IApplicationState) => state.updateItem);
  const [showSelect, setShowSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openModalCreate, setOpenModalCreate] = useState<{
    open: boolean;
    data?: IItems;
    init?: any[];
  }>({
    open: false,
  });

  // CREATE
  useEffect(() => {
    if (createItemsResult.data && !createItemsResult.error) {
      handleGetListAspect();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createItemsResult]);

  // UPDATE
  useEffect(() => {
    if (updateItemResult.data && !updateItemResult.error) {
      handleGetListAspect();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemResult]);

  const handleGetListAspect = () => {
    if (selectedCategoryId)
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: selectedCategoryId,
          questionId: question._id,
          isWithGuidedQuestion: true,
        })
      );
  };
  // SORT CATEGORY
  const [listSortCategory, setListSortCategory] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortCategory(
      listCategories.data?.sort((a, b) => a.order - b.order)?.map((item) => ({ ...item, id: item._id })) ?? []
    );
  }, [listCategories.data]);

  // SORT ASPECT
  const [listSortAspect, setListSortAspect] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortAspect(sortItemOrder(listAspects.data)?.map((item) => ({ ...item, id: item._id })) ?? []);
  }, [listAspects.data]);

  const listSortAspectFilterCat = listSortAspect.filter((item) => item.parentId === selectedCategoryId);

  //GET LIST
  useEffect(() => {
    handleGetListCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetListCategories = () => {
    dispatch(
      getListCategories({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.category,
      })
    );
  };

  const handleCategoryClick = (category: IItems) => {
    setSelectedCategoryId(category._id);
    setSelectedAspectId(null);
    setSelectedItems([]);
    if (category._id !== selectedCategoryId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: category._id,
          questionId: question._id,
          isWithGuidedQuestion: true,
        })
      );
    }
  };
  const handleCloseModal = () => {
    setOpenModalCreate({
      open: false,
    });
  };
  const handleCreateEdit = (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item, index) => ({
      name: item.title,
      description: item.description,
      type: ETypeItem["guided.question"],
      parentId: question._id,
      ...(openModalCreate.data
        ? {
            aspectId: openModalCreate.data?.aspectId,
          }
        : {
            aspectId: openModalCreate.init?.[index].id,
          }),
    }));
    if (openModalCreate.data) {
      dispatch(updateItem({ ...dataSend?.[0], id: openModalCreate.data._id }));
    } else {
      dispatch(createItems({ items: dataSend }));
    }
  };

  const handleAction = ({ type, typeAction, data, deleteId }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMultiple([deleteId]);
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };

  const onDeleteAll = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMultiple(selectedItems);
      }
    });
  };
  const handleDeleteMultiple = async (items) => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.DELETE_GUIDED_QUESTIONS);
      const res = await Requests.post(apiUrl, {
        questionId: question._id,
        items: items,
      });
      if (res.status === 200) {
        dispatch(showMessage("Delete guided question success", "success"));
        setSelectedItems([]);
        dispatch(
          getListAspects({
            skip: 0,
            limit: Number.MAX_SAFE_INTEGER,
            parentId: selectedCategoryId,
            questionId: question._id,
            isWithGuidedQuestion: true,
          })
        );
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const selectedAspect = useMemo(() => {
    return listSortAspect.find((aspect) => aspect._id === selectedAspectId);
  }, [selectedAspectId, listSortAspect]);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "16px",
          marginBottom: "16px",
          "& button": {
            marginBottom: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Button variant="contained" color="secondary" onClick={onBack}>
            Back
          </Button>
          <Box dangerouslySetInnerHTML={{ __html: question.name }} />
        </Box>
        <BackgroundKnowledgeModal detail={question} refetchQuestionPart2={refetchQuestionPart2} />
      </Box>
      <Box sx={{ ...styles.boxContainer, height: "calc(100% - 50px)" }}>
        <Box>
          <Typography variant="h3">Category</Typography>
          <Box className="box-item">
            {listSortCategory?.map((category) => (
              <Box
                key={category._id}
                onClick={() => handleCategoryClick(category)}
                sx={{
                  ...styles.styleItem,
                  backgroundColor: selectedCategoryId === category._id ? "lightblue" : "transparent",
                  "& .title": {
                    display: "flex",
                    "& .count": {
                      marginLeft: "5px",
                    },
                  },
                }}
              >
                <Box
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      category.name +
                        `${category.childrenCount > 0 ? `<b class="count">(${category.childrenCount})</b>` : ""}` || "",
                  }}
                />
                <Box className="description" dangerouslySetInnerHTML={{ __html: category.description || "" }} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="flex-1">
          {!!selectedCategoryId && (
            <>
              {listSortAspectFilterCat?.length > 0 && (
                <>
                  <FormGenerateGuidedQ
                    questionContent={extractContent(question.name)}
                    onGenGuidedQ={(items: any[]) => {
                      setOpenModalCreate({
                        open: true,
                        init: items.map((item) => ({ ...item, title: item.guidedQuestion ?? "" })),
                      });
                    }}
                    aspects={listSortAspectFilterCat}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Typography variant="h3">Aspect</Typography>
                    <Button
                      sx={{ marginLeft: "10px" }}
                      variant="contained"
                      onClick={() => {
                        if (showSelect) {
                          setSelectedItems([]);
                        }
                        setShowSelect(!showSelect);
                      }}
                      color="warning"
                    >
                      {showSelect ? "Deselect" : "Select"} Multiple
                    </Button>
                    {showSelect && (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => {
                          setSelectedItems((prev) =>
                            listSortAspectFilterCat?.length == prev.length
                              ? []
                              : listSortAspectFilterCat?.map((item) => item._id) ?? []
                          );
                        }}
                      >
                        Select All
                      </Button>
                    )}
                    {showSelect && selectedItems.length ? (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => {
                          onDeleteAll();
                        }}
                        color="error"
                      >
                        Delete All
                      </Button>
                    ) : null}
                  </Box>
                </>
              )}
              <Box className="box-item" sx={{ width: "100%", overflowY: "auto", maxHeight: "calc(100vh - 280px)" }}>
                {listSortAspectFilterCat?.map((aspect) => (
                  <Box
                    key={aspect._id}
                    sx={{
                      ...styles.styleItem,
                      backgroundColor: selectedAspectId === aspect._id ? "lightblue" : "transparent",
                      "& .title": {
                        display: "flex",
                        "& .count": {
                          marginLeft: "5px",
                        },
                      },
                    }}
                    onClick={() => setSelectedAspectId(aspect._id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {showSelect ? (
                        <Box>
                          <Checkbox
                            checked={selectedItems.includes(aspect._id)}
                            onChange={(e) => {
                              setSelectedItems((prev) =>
                                prev.includes(aspect._id)
                                  ? prev.filter((item) => item !== aspect._id)
                                  : [...prev, aspect._id]
                              );
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            inputProps={{ "aria-label": "Select item" }}
                            className="check-box"
                          />
                        </Box>
                      ) : null}
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <Box
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html:
                              aspect.name +
                                `${aspect.childrenCount > 0 ? `<b class="count">(${aspect.childrenCount})</b>` : ""}` ||
                              "",
                          }}
                        />
                        {/* <Box className="description" dangerouslySetInnerHTML={{ __html: aspect.description || "" }} /> */}
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          Guided question
                        </Typography>
                        <Box
                          sx={{
                            paddingRight: "40px",
                            position: "relative",
                          }}
                        >
                          <Box
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: aspect?.guidedQuestion?.name ?? "",
                            }}
                          />
                          <MenuAction
                            hide={aspect?.guidedQuestion ? [] : [EAction.delete]}
                            handleClick={(typeAction: EAction) => {
                              if (!aspect?.guidedQuestion && typeAction === EAction.edit) {
                                setOpenModalCreate({
                                  open: true,
                                  init: [
                                    {
                                      ...aspect,
                                    },
                                  ],
                                });
                              } else {
                                handleAction({
                                  typeAction,
                                  type: ETypeItem["guided.question"],
                                  data: aspect?.guidedQuestion,
                                  deleteId: aspect._id,
                                });
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        {/* <Box className="flex-1" sx={{ overflowY: "auto" }}>
          <Typography variant="h3">Guided questions</Typography>
          {!!selectedAspectId && !!selectedAspect && (
            <GenerateListByGuideQBox question={question} aspect={selectedAspect} />
          )}
        </Box> */}
        <CreateEditItemModal
          handleCreateEdit={handleCreateEdit}
          openModal={openModalCreate.open}
          handleCloseModal={handleCloseModal}
          isLoading={createItemsResult.isFetching}
          titleModal={`Create guided questions`}
          data={openModalCreate.data}
          init={openModalCreate.data ? undefined : openModalCreate.init}
          isGuidedQuestion={openModalCreate.data ? false : true}
        />
      </Box>
    </Box>
  );
};

export default GenerateQuidedQuestions;
