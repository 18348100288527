import { ENDPOINTS, ICreateItem, IDetailItem, IGetList, IItemUpdate, IItems, Requests, getApiUrl } from "@/common";

export const fetchListItems = async (payload: IGetList): Promise<IItems[]> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_LIST_ITEMS, payload);
  const { data }: any = await Requests.get(apiUrl);

  return data;
};

// export const fetchListAllQuestions = async (payload: IGetList): Promise<IItems[]> => {
//   const apiUrl = getApiUrl(ENDPOINTS.GET_LIST_ITEMS, payload);
//   const { data }: any = await Requests.get(apiUrl);
//   return data;
// };

export const fetchDetailItem = async ({ id }: IDetailItem): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);
  const { data }: any = await Requests.get(apiUrl);
  return data;
};

export const addMultipleItems = async (payload: ICreateItem): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.CREATE_MULTIPLE_ITEMS);
  // check upload files
  const filesOrigin = payload?.items?.map((item: any) => item.file);
  const filesOriginExist = filesOrigin?.filter((item: any) => item);
  let inputWithFiles: any;
  if (filesOriginExist?.length) {
    const urlUploadFile = getApiUrl(ENDPOINTS.UPLOAD_FILES);
    const dataUpload: any = await Requests.post(
      urlUploadFile,
      {
        files: filesOriginExist,
      },
      true,
      false
    );
    let indexDataUpload = 0;
    inputWithFiles = payload?.items?.map((item: any) => {
      if (item?.file) {
        const dataItem = {
          ...item,
          imageUrl: dataUpload?.files?.[indexDataUpload]?.path,
        };
        indexDataUpload += 1;
        return dataItem;
      }
      return item;
    });
  }
  const dataRequest = filesOriginExist?.length
    ? {
        items: inputWithFiles,
      }
    : payload;
  const { data } = await Requests.post(apiUrl, dataRequest);
  return data;
};

export const updateItem = async (payload: IItemUpdate): Promise<IItems> => {
  const { id, ...rest } = payload;
  const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);
  // check upload file
  if (rest?.file) {
    const urlUploadFile = getApiUrl(ENDPOINTS.UPLOAD_FILES);
    const dataUpload: any = await Requests.post(
      urlUploadFile,
      {
        files: rest.file,
      },
      true,
      false
    );
    rest.imageUrl = dataUpload?.files?.[0]?.path;
  }
  const { data } = await Requests.patch(apiUrl, rest);
  return data;
};
export const deleteItem = async (id: string): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);
  const { data } = await Requests.delete(apiUrl);
  return data;
};

export const chatWithAI = async (prompt: string): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.CHAT_WITH_AI);
  const { data } = await Requests.post(apiUrl, { prompt });
  return data;
};
