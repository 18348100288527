import { IQueryOptions } from ".";
import { useMutation } from "react-query";
import {
  createWordbook,
  deleteVoca,
  deleteWordbook,
  editWordbook,
  getListCommunityWordbooks,
  getListVocabsByWordbook,
  getListWordbooks,
  getOneWordbook,
  insertTermsWordbook,
  saveWord,
} from "../api/wordbank.service";

export const useCreateWordbook = (options: IQueryOptions) => {
  return useMutation(createWordbook, options);
};

export const useGetListWordbooks = (options: IQueryOptions) => {
  return useMutation(getListWordbooks, options);
};

export const useDeleteWordbook = (options: IQueryOptions) => {
  return useMutation(deleteWordbook, options);
};

export const useEditWordbook = (options: IQueryOptions) => {
  return useMutation(editWordbook, options);
};

export const useGetOneWordbook = (options: IQueryOptions) => {
  return useMutation(getOneWordbook, options);
};

export const useSaveWord = (options: IQueryOptions) => {
  return useMutation(saveWord, options);
};

export const useGetListVocabsByWordbook = (options: IQueryOptions) => {
  return useMutation(getListVocabsByWordbook, options);
};

export const useDeleteVoca = (options: IQueryOptions) => {
  return useMutation(deleteVoca, options);
};

export const useInsertTermsWordbook = (options: IQueryOptions) => {
  return useMutation(insertTermsWordbook, options);
};

export const useGetListCommunityWordbooks = (options: IQueryOptions) => {
  return useMutation(getListCommunityWordbooks, options);
};
