import BackgroundKnowledgeDetail from "@/components/BackgroundKnowledgeDetail";
import DynamicBreadcrumbs from "@/components/DynamicBreadcrumbs";
import { ROUTES } from "@/constants";
import {
  editCustomAnswer,
  getDetailItem,
  getListAnswerOfUser,
  IApplicationState,
  IItems,
  parseParamUrl,
  saveCustomAnswer,
  saveEditCustomAnswer,
} from "@/store-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomAnswer from "./components/CustomAnswer";

interface AnswerPageProps {
  isPartTwo?: boolean;
  question?: IItems;
  guidedQuestions?: any[];
  answersByAspect?: any;
  name?: string;
}
const AnswerPage: React.FC<AnswerPageProps> = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { topicId, questionId } = useParams();
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const { isPartTwo, question, guidedQuestions, answersByAspect, name, contentSuggestion } = location?.state ?? {};

  const isFromProfile = Boolean(location.state);

  const [boldIndexes, setBoldIndexes] = useState<number[]>([]);
  const isQuestionPart2 = location?.pathname.includes("/question-part2");

  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const isHasContentEdit = Boolean(editCustomAnswerContent?.data && editCustomAnswerContent?.data?.trim());
  const questionReducer = useSelector((state: IApplicationState) => state.detailItem);
  const detailQuestion = isQuestionPart2
    ? {
        data: question,
      }
    : questionReducer;
  const infoSaveCustomAnswer = useSelector((state: IApplicationState) => state.saveCustomAnswer);

  useEffect(() => {
    if (isPartTwo) {
      // console.log("isPartTwo", isPartTwo);
      setTimeout(() => {
        dispatch(editCustomAnswer(contentSuggestion ?? ""));
      }, 500);
    }
  }, []);
  const handleGetListAnswerOfUser = () => {
    dispatch(
      getListAnswerOfUser({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
      })
    );
  };

  const handleSaveCustomAnswer = () => {
    const dataSave = {
      questionId: detailQuestion?.data?._id ?? "",
      question: detailQuestion?.data?.name ?? "",
      answer: editCustomAnswerContent?.data?.toString(),
      idea: JSON.stringify(isQuestionPart2 ? guidedQuestions : listMyIdeaPath?.data),
      boldIndex: boldIndexes,
      topicId: topicId ?? (detailQuestion?.data?.parentId || ""),
    };
    dispatch(saveCustomAnswer(dataSave));
    handleGetListAnswerOfUser();
  };

  const handleSaveEditCustomAnswer = () => {
    dispatch(
      saveEditCustomAnswer({
        questionId: location.state?.questionId || infoSaveCustomAnswer?.data?.questionId,
        question: location.state?.question || infoSaveCustomAnswer?.data?.question,
        answer: editCustomAnswerContent?.data?.toString(),
        idea: location.state?.idea || JSON.stringify(listMyIdeaPath?.data),
        boldIndex: boldIndexes,
        id: location.state?._id || infoSaveCustomAnswer?.data?._id,
        topicId: isFromProfile ? detailQuestion?.data?.parentId || location.state?.topicId : topicId,
      })
    );
    handleGetListAnswerOfUser();
  };

  //GET DETAIL QUESTION
  useEffect(() => {
    if (location.state?.questionId && location.state?.questionId !== detailQuestion?.data?._id) {
      dispatch(getDetailItem(`${location?.state?.questionId}`));
    }
  }, [isFromProfile]);

  useEffect(() => {
    if (isFromProfile || infoSaveCustomAnswer?.data) {
      setBoldIndexes(location?.state?.boldIndex ?? infoSaveCustomAnswer?.data?.boldIndex);
    }
  }, [isFromProfile, infoSaveCustomAnswer?.data]);

  useEffect(() => {
    return () => {
      dispatch(editCustomAnswer(""));
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        height: "100vh",
        overflow: "hidden",
        padding: "0 16px",
        marginTop: "12px",
      }}
    >
      <DynamicBreadcrumbs
        items={[
          {
            text: "Learn",
            link: ROUTES.LEARN,
          },
          {
            text: `Topic & Question`,
            link: parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
              "type-question": typeQuestion,
            }),
          },
          {
            text: `Ideas`,
            link: parseParamUrl(ROUTES.LEARN_QUESTION_PAGE.replace(":questionId", questionId ?? ""), undefined, {
              "type-question": typeQuestion,
            }),
          },
          {
            text: `Answer`,
          },
        ]}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: isPartTwo ? question?.name : detailQuestion?.data?.name ?? "",
          }}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "8px",
            "& button": {
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            },
            "& .isHiddenSave": {
              width: "auto",
              display: isHasContentEdit ? "block" : "none",
            },
            "& .isHiddenSaveEdit": {
              width: "auto",
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {isPartTwo ? (
              question?.backgroundKnowledge ? (
                <BackgroundKnowledgeDetail detail={question?.backgroundKnowledge} />
              ) : null
            ) : detailQuestion?.data?.backgroundKnowledge ? (
              <BackgroundKnowledgeDetail detail={detailQuestion?.data} />
            ) : null}
            {isFromProfile ||
            (infoSaveCustomAnswer?.data?.questionId &&
              infoSaveCustomAnswer?.data?.questionId === detailQuestion?.data?._id) ? (
              <Button
                variant="contained"
                onClick={handleSaveEditCustomAnswer}
                className="isHiddenSaveEdit"
                color="success"
              >
                <SaveIcon />
                Save Edit
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSaveCustomAnswer} className="isHiddenSave" color="success">
                <SaveIcon />
                Save
              </Button>
            )}

            <Button variant="outlined" onClick={handleGoBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <CustomAnswer
            question={question ?? detailQuestion?.data ?? undefined}
            isPartTwo={isPartTwo}
            guidedQuestions={guidedQuestions}
            answersByAspect={answersByAspect}
            name={name}
            boldIndexes={boldIndexes}
            setBoldIndexes={setBoldIndexes}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AnswerPage;
