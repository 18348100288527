import { IWordbook } from "@/common/types/wordbank";
import { useCreateWordbook, useEditWordbook } from "@/services-react-query/queries/wordbank.query";
import { showMessage } from "@/store-redux";
import { Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

const FormWordbookModal = (props) => {
  const { onClose, init, questionId } = props;
  const dispatch = useDispatch();
  const { control, handleSubmit, reset } = useForm<IWordbook>({
    defaultValues: init
      ? {
          name: init.name ?? "",
          description: init.description ?? "",
        }
      : {
          name: "",
          description: "",
        },
  });

  const { mutate: createWordbook, isLoading: loadingCreate } = useCreateWordbook({
    onSuccess: (data: any) => {
      dispatch(showMessage("Create wordbook success", "success"));
      onClose(data);
    },
  });
  const { mutate: editWorkbook, isLoading: loadingEdit } = useEditWordbook({
    onSuccess: (data: any) => {
      dispatch(showMessage("Edit wordbook success", "success"));
      onClose(data);
    },
  });
  const loading = loadingCreate || loadingEdit;

  const onSave = (data: IWordbook) => {
    const dataSend = {
      ...data,
      name: data.name,
      description: data.description,
      ...(questionId ? { questionId } : {}),
    };
    if (init)
      editWorkbook({
        id: init._id,
        ...dataSend,
      });
    else createWordbook(dataSend);
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          minWidth: {
            xs: "100vw",
            sm: "500px",
          },
          maxWidth: {
            xs: "100vw",
            sm: "600px",
          },
          width: "100%",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSave)}>
          <Typography variant="h5"></Typography>
          <Controller
            name="name"
            control={control as any}
            render={({ field }: { field: any }) => <TextField {...field} label="Name" />}
          />
          <Controller
            name="description"
            control={control as any}
            render={({ field }: { field: any }) => <TextField {...field} label="Description" />}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button onClick={() => onClose()} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" disabled={loading}>
              {loading ? <CircularProgress size={30} /> : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default FormWordbookModal;
