import AbcIcon from "@mui/icons-material/Abc";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import QuizIcon from "@mui/icons-material/Quiz";
import ReportIcon from "@mui/icons-material/Report";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IdeaIcon, LogoutIcon, QuestionIcon, TwoPeopleIcon } from "../../../assets/icons";
import logoIcon from "../../../assets/logo.svg";
import { KeyTokenName, ROUTES, appVersion } from "../../../constants";
import { ERole, IApplicationState } from "../../../store-redux";
import ChatAIPopup from "@/pages/CMS/ChatAIPopup";
const drawerWidth = 240;

interface Props {
  children: React.ReactNode;
}

const dashboardRoutes = [
  {
    path: ROUTES.USERS,
    name: "Users",
    icon: <TwoPeopleIcon />,
  },
  {
    path: ROUTES.QUESTION_TYPE_CMS,
    name: "Question-type oriented Learning",
    icon: <QuizIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.TOPIC_QUESTION,
    name: "Topic & Question",
    icon: <QuestionIcon />,
  },
  {
    path: ROUTES.QUESTION_PART2,
    name: "Question Part 2",
    icon: <Diversity1Icon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.CMS_LEARN_VOCA,
    name: "Learn Voca",
    icon: <SchoolIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.CATEGORY_ASPECT,
    name: "Category & Aspects",
    icon: <IdeaIcon />,
  },
  {
    path: ROUTES.CMS_WORDBANK,
    name: "Wordbank",
    icon: <AccountBalanceIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.CHALLENGE_CMS,
    name: "Challenge",
    icon: <AssignmentIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.PERSONALIZED_CMS,
    name: "Personalized",
    icon: <SensorOccupiedIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.STRUCTURE_CMS,
    name: "Structures",
    icon: <AccountTreeIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.GRAMMAR_CMS,
    name: "Grammars",
    icon: <AbcIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.AI_CALL_STATIS_CMS,
    name: "AI Call statis",
    icon: <AnalyticsIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.LLM_ENERGY_CMS,
    name: "LLM Engery",
    icon: <ElectricBoltIcon htmlColor="#090A0A" />,
  },
  {
    path: ROUTES.DICT_REPORT_CMS,
    name: "Dict report",
    icon: <ReportIcon htmlColor="#090A0A" />,
  },
  {
    path: "logout",
    name: "Logout",
    icon: <LogoutIcon />,
  },
];

export default function Layout(props: Props) {
  const { children } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const currentInfoUser = useSelector((state: IApplicationState) => state.currentUser);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem(KeyTokenName);
    window.location.href = "/login";
  };

  const handleTabClick = (path: string) => {
    setSelectedTab(path);
    if (path === "logout") {
      Swal.fire({
        title: "Are you sure you want to logout?",
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleLogout();
        }
      });
    } else {
      navigate(path);
    }
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const drawer = (
    <div>
      <Divider />
      <List>
        {dashboardRoutes.map((item, index) => {
          if (currentInfoUser.data?.role === ERole.ADMIN && item.path === ROUTES.USERS) {
            return null;
          }
          return (
            <React.Fragment key={index}>
              {item.path === "logout" ? <Divider /> : null}
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleTabClick(item.path)} selected={selectedTab === item.path}>
                  <ListItemIcon sx={() => ({ marginRight: "12px", minWidth: "auto" })}>
                    {item.icon ?? <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );

  useEffect(() => {
    if (currentInfoUser.data) {
      if (currentInfoUser.data?.role === ERole.USER) {
        navigate(ROUTES.LOGIN);
      }
    }
  }, [currentInfoUser.data, navigate]);

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `100%` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          zIndex: 1,
          boxShadow: "0px 10px 10px rgba(0,0,0,0.08)",
        }}
      >
        <Toolbar
          sx={() => ({
            width: "100%",
          })}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={() => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            })}
          >
            <Box
              sx={() => ({
                display: "flex",
                alignItems: "center",
              })}
            >
              <img src={logoIcon} />
              <Typography
                component="span"
                sx={() => ({
                  fontSize: "18px",
                  fontWeight: "600",
                  textTransform: "none",
                  marginLeft: "14px",
                })}
                color="primary"
              >
                fongfluency lab
              </Typography>
            </Box>
            <Avatar src="/images/avatar.png" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          position: "relative",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: "9 !important",
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              zIndex: 1,
              position: "relative",
            },
          }}
        >
          {drawer}
          <Box
            sx={{
              position: "absolute",
              bottom: "25px",
              left: "60px",
            }}
          >
            {appVersion}
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              top: "75px",
              border: "none",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              left: "10px",
              height: "calc(100% - 90px)",
              zIndex: 1,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            position: "absolute",
            bottom: "25px",
            left: "60px",
            zIndex: "1",
          }}
        >
          {appVersion}
        </Box>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          maxWidth: {
            xs: "100vw",
            sm: "calc(100vw - 260px)",
          },
          marginLeft: "30px",
          height: "calc(100vh - 85px)",
          overflowY: "auto",
          marginTop: "80px",
        }}
      >
        {children}
      </Box>
      <ChatAIPopup />
    </Box>
  );
}
