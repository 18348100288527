import { IApplicationState, IItems, parseParamUrl, removeIntoCart } from "@/store-redux";
import { Box, Button, IconButton, Tab, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/constants";
import { useState } from "react";
import AbcIcon from "@mui/icons-material/Abc";
import KeywordToIdea from "./KeywordToIdea";
import FreestyleToIdea from "./FreestyleToIdea";
import { ConversationIcon } from "@/assets/icons";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const MyIdeaPath = () => {
  const theme = useTheme();
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const { questionId } = useParams();
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const onRemoveIdea = (idea: IItems) => {
    dispatch(removeIntoCart(idea));
  };

  const [tab, setTab] = useState("1");
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={
        {
          //  boxShadow: "0px 4px 10px 0px #00000026"
        }
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px",
          height: "50px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              marginRight: "5px",
            }}
          >
            My Idea Path
          </Typography>
          {listMyIdeaPath.data?.length > 0 && (
            <Box
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: "50%",
                padding: "0 5px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "18px",
              }}
            >
              {listMyIdeaPath.data?.length}
            </Box>
          )}
        </Box>
        {listMyIdeaPath.data?.length > 0 && (
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.main,
              color: "#fff",
              textTransform: "unset",
              height: "37px",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
            onClick={() => {
              const url = parseParamUrl(ROUTES.ANSWER_QUESTION.replace(":questionId", questionId ?? ""), undefined, {
                "type-question": typeQuestion,
              });
              navigate(url);
            }}
          >
            Customize your answer
          </Button>
        )}
      </Box>
      <Box
        sx={{
          padding: "0 10px",
          height: "calc(100vh - 80px)",
          overflowY: "auto",
          "& .MuiTabPanel-root": {
            padding: 0,
          },
          "& .MuiTabs-flexContainer": {},
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        <TabContext value={tab}>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              sx: {},
            }}
            TabScrollButtonProps={{}}
            sx={() => ({
              "& button": {
                flex: 1,
                fontSize: "15px",
                textTransform: "none",
                minHeight: "40px",
                backgroundColor: "#eee",
                color: "gray",
                opacity: "0.8",
                maxWidth: "none",
              },
              "& .Mui-selected": {
                fontWeight: "bold",
                color: "#c44141 !important",
                backgroundColor: "#fed8d8 !important",
                opacity: 1,
                "& svg": {
                  fill: "#c44141",
                },
              },
            })}
          >
            <Tab label="Keywords to idea" value="1" icon={<AbcIcon />} iconPosition="start" />
            <Tab
              label="Conversation to idea"
              value="2"
              icon={<ConversationIcon height={20} width={20} fill="#6A6969" />}
              iconPosition="start"
            />
          </TabList>
          <TabPanel value="1">
            <>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#6A6969",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                Chat with Bot
              </Typography>
              <KeywordToIdea question={detailQuestion?.data} />
            </>
          </TabPanel>
          <TabPanel value="2">
            <>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#6A6969",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                Freestyle
              </Typography>
              <FreestyleToIdea question={detailQuestion?.data} />
            </>
          </TabPanel>
        </TabContext>
        <Typography
          sx={{
            color: "#6A6969",
            fontSize: "14px",
          }}
        >
          Ý tưởng đã chọn
        </Typography>
        <Box>
          {listMyIdeaPath.data?.map((idea, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "5px 0",
                  ...(index !== listMyIdeaPath.data.length - 1 ? { borderBottom: "1px solid #D9D9D9" } : {}),
                }}
                key={index}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "14px!important",
                      fontWeight: "bold",
                      "& *": {
                        fontSize: "14px !important",
                      },
                    }}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
                  />
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                    className="description"
                    dangerouslySetInnerHTML={{ __html: idea.description || "" }}
                  />
                </Box>
                <IconButton
                  sx={{
                    marginLeft: "5px",
                  }}
                  onClick={() => onRemoveIdea(idea)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default MyIdeaPath;
