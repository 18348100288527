import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { ETypeItem, IItems } from "../../store-redux";
import { styles } from "./styles";
import { useEffect } from "react";
import { UploadFile } from "../UploadFile";

interface CreateEditItemModalProps {
  openModal: boolean;
  handleCreateEdit: (data: IFormDataProps) => void;
  handleCloseModal: () => void;
  isLoading?: boolean;
  titleModal: string;
  data?: IItems;
  isIdea?: boolean;
  isQuestion?: boolean;
  isUploadFile?: boolean;
  init_titles?: string[];
  init?: any[];
  isGuidedQuestion?: boolean;
  showPublic?: boolean;
}
export interface IFormDataProps {
  values: {
    title: string;
    description?: string;
    type?: ETypeItem;
    public?: boolean;
    file?: File;
    imageUrl?: string;
    aspectId: string;
  }[];
}

const optionTypeIdeas = [
  {
    id: ETypeItem["idea.grammar"],
    label: "Grammar",
  },
  {
    id: ETypeItem["idea.main"],
    label: "Idea",
  },
  {
    id: ETypeItem["idea.structure"],
    label: "Structure",
  },
];

function CreateEditItemModal(props: CreateEditItemModalProps) {
  const {
    openModal,
    handleCreateEdit,
    handleCloseModal,
    isLoading = false,
    titleModal,
    data,
    isIdea,
    isQuestion,
    isUploadFile,
    init_titles,
    init,
    isGuidedQuestion,
    showPublic,
  } = props;

  const { control, handleSubmit, reset, setValue, watch } = useForm<IFormDataProps>({
    defaultValues: {
      values: [
        {
          title: "",
          description: "",
          type: isIdea ? ETypeItem["idea.main"] : undefined,
          public: true,
        },
      ],
    },
    // mode: "onBlur",
  });

  const onSubmit = (data: IFormDataProps) => {
    handleCreateEdit(data);
  };

  const { fields, append, remove } = useFieldArray({
    name: "values",
    control,
  });

  const onCloseModal = () => {
    handleCloseModal();
    reset({
      values: [
        {
          title: "",
          description: "",
          type: isIdea ? ETypeItem["idea.main"] : undefined,
          public: isQuestion ? true : undefined,
        },
      ],
    });
  };

  useEffect(() => {
    if (openModal) {
      if (data) {
        reset({
          values: [
            {
              title: data.name,
              description: data.description ?? "",
              type: isIdea ? data.type : undefined,
              public: isQuestion || showPublic ? data.public || false : undefined,
              ...(data?.imageUrl ? { imageUrl: data.imageUrl } : {}),
            },
          ],
        });
      } else {
        if (Array.isArray(init_titles) && init_titles.length) {
          reset({
            values: init_titles.map((title) => ({
              title: title,
              description: "",
              type: isIdea ? ETypeItem["idea.main"] : undefined,
              public: isQuestion || showPublic ? true : undefined,
            })),
          });
        }
        if (Array.isArray(init) && init.length) {
          reset({
            values: init.map((item) => ({
              title: item.title,
              description: "",
              type: isIdea ? ETypeItem["idea.main"] : undefined,
              public: isQuestion || showPublic ? true : undefined,
              aspectId: item.aspectId,
              aspectTitle: item.name,
            })),
          });
        }
      }
    } else {
      reset({
        values: [
          {
            title: "",
            description: "",
            type: isIdea ? ETypeItem["idea.main"] : undefined,
          },
        ],
      });
    }
  }, [openModal]);

  return (
    <Modal open={openModal} onClose={onCloseModal} sx={styles.modalContainer}>
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <Box className="header">
          <Typography component={"h3"}>{titleModal}</Typography>
          <IconButton className="icon-close" onClick={onCloseModal}>
            <CloseIcon color="success" />
          </IconButton>
        </Box>
        <Box className="sub-header">
          {isGuidedQuestion && (
            <Typography
              component={"h4"}
              sx={{
                width: "120px",
                minWidth: "120px",
              }}
            >
              Aspect
            </Typography>
          )}
          <Typography component={"h4"}>Title</Typography>
          <Typography component={"h4"}>Description</Typography>
          <Box></Box>
        </Box>
        <Box className="body_box">
          {fields.map((field, index) => {
            return (
              <section className={"section"} key={field.id}>
                {isGuidedQuestion && (
                  <Box
                    sx={{
                      width: "120px",
                      minWidth: "120px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: init?.[index]?.name ?? "",
                    }}
                  ></Box>
                )}
                <Controller
                  name={`values.${index}.title`}
                  control={control}
                  render={({ field }) => (
                    <SunEditor
                      hideToolbar
                      onChange={(content) => field.onChange(content)}
                      defaultValue={init?.[index]?.title ?? init_titles?.[index] ?? data?.name ?? ""}
                    />
                  )}
                />

                <Controller
                  name={`values.${index}.description`}
                  control={control}
                  render={({ field }) => (
                    <SunEditor
                      hideToolbar
                      onChange={(content) => field.onChange(content)}
                      defaultValue={data?.description ?? ""}
                    />
                  )}
                />
                {isIdea ? (
                  <Box sx={{}}>
                    <Controller
                      name={`values.${index}.type`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {optionTypeIdeas.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                ) : null}
                {isQuestion || showPublic ? (
                  <Box
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        flexDirection: "column-reverse",
                      },
                    }}
                  >
                    <Controller
                      name={`values.${index}.public`}
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  console.log("e.target.checked", e.target.checked);
                                }}
                                color="secondary"
                              />
                            }
                            label="Public"
                          />
                        </FormGroup>
                      )}
                    />
                  </Box>
                ) : null}
                {isUploadFile ? (
                  <UploadFile
                    setValue={setValue}
                    valueDefault={watch(`values.${index}.imageUrl`)}
                    name={`values.${index}.file`}
                  />
                ) : null}
                {index !== 0 && !isGuidedQuestion ? (
                  <Box className="icon-delete">
                    <IconButton onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon color="warning" />
                    </IconButton>
                  </Box>
                ) : (
                  <Box className="icon-delete"></Box>
                )}
              </section>
            );
          })}
        </Box>
        <Box className="list-btn">
          {!isGuidedQuestion && (
            <IconButton
              type="button"
              onClick={() =>
                append({
                  title: "",
                  description: "",
                  type: isIdea ? ETypeItem["idea.main"] : undefined,
                })
              }
              disabled={!!data}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}

          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={30} /> : "Save"}
          </Button>
        </Box>
      </form>
    </Modal>
  );
}

export default CreateEditItemModal;
