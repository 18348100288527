import { ETypeItem, IItems } from "@/common";
import NoDataComponent from "@/components/NoDataComponent";
import { extractContent } from "@/helpers";
import { useGetQuestionPart2All } from "@/services-react-query/queries";
import { Box, Typography, useTheme } from "@mui/material";
interface ListQuestionPart2Props {
  groupSelected: IItems | null;
  questionSelected: IItems | null;
  setQuestionSelected: (item: any) => void;
}
const ListQuestionPart2 = ({ groupSelected, questionSelected, setQuestionSelected }: ListQuestionPart2Props) => {
  const { _id: groupId } = groupSelected;
  const theme = useTheme();
  const { data: listQuestionPart2, isSuccess: isSuccess } = useGetQuestionPart2All({
    query: {
      type: ETypeItem["question.part2"],
      limit: Number.MAX_SAFE_INTEGER,
      skip: 0,
      parentId: groupId ?? "",
      isOnlyPublic: true,
    },
    options: {
      enabled: !!groupId,
    },
  });
  const renderTitleTopic = extractContent(groupSelected?.name).replace(/^[A-Z]\d\s*-\s*\d+\.\s*/, "");

  return (
    <Box>
      <Box
        sx={{
          borderBottom: "1px solid #D9D9D9",
          padding: "16px 20px 16px 20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            "& p": {
              fontSize: "16px",
              fontWeight: "600",
            },
          }}
        >
          <Typography>Question</Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              height: "20px",
              width: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {listQuestionPart2?.length ?? 0}
          </Box>
        </Box>
        {!!renderTitleTopic && (
          <Typography
            sx={{
              color: "#6A6969",
              fontSize: "14px",
            }}
          >
            Choose question (group “{renderTitleTopic}")
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 315px)",
        }}
      >
        {listQuestionPart2?.map((question: IItems, index: number) => (
          <Box
            sx={{
              borderBottom: "1px solid #D9D9D9",
              padding: "5px 0",
            }}
            key={question._id}
            onClick={() => setQuestionSelected(question)}
            className="box-item"
          >
            <Box
              sx={{
                padding: "5px",
                cursor: "pointer",
                fontSize: "14px",
                "&:hover": {
                  background: "#F7F7F7",
                },
                ...(questionSelected?._id === question._id ? { background: "#F7F7F7" } : {}),
              }}
            >
              {index + 1}. {extractContent(question.name).replace(/^[A-Z]\d\s*-\s*\d+\.\s*/, "")}
            </Box>
            {/* <Box className="description" dangerouslySetInnerHTML={{ __html: question.description || "" }} /> */}
          </Box>
        ))}
      </Box>
      {listQuestionPart2?.length === 0 && isSuccess && <NoDataComponent />}
    </Box>
  );
};

export default ListQuestionPart2;
