import { IItems } from "@/common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import SunEditor from "suneditor-react";

interface IProps {
  detail: IItems;
}

function BackgroundKnowledgeDetail({ detail }: IProps) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (open) {
      setInputValue(detail?.backgroundKnowledge || "");
    }
  }, [detail?.backgroundKnowledge, open]);

  return (
    <>
      <Button
        variant="text"
        color="info"
        sx={{
          fontSize: "14px",
          textTransform: "unset",
          padding: "4px",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Background knowledge
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            minWidth: "80vw",
            "& .sun-editor .se-wrapper .se-wrapper-wysiwyg": {
              height: "100% !important",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
          }}
        >
          Background knowledge information
        </DialogTitle>
        <DialogContent
          sx={{
            "& .sun-editor": {},
          }}
        >
          <SunEditor disable={true} hideToolbar setContents={inputValue.toString()} />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            gap: "8px",
            "& button": {
              textTransform: "none",
              fontSize: "16px",
            },
          }}
        >
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BackgroundKnowledgeDetail;
