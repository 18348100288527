import { ETypeItem, parseParamUrl } from "@/common";
import LayoutTab from "@/components/LayoutTab";
import { ROUTES } from "@/constants";
import { useGetGroupQuestionPart2All } from "@/services-react-query/queries";
import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListQuestionPart2 from "./ListQuestionPart2";
import { useNavigate } from "react-router-dom";
// import ChatWithBotPart2 from "./ChatWithBotPart2";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import AbcIcon from "@mui/icons-material/Abc";
// import KeywordToIdea from "../LearnQuestionPage/KeywordToIdea";

// const CHAT_TYPE_MORE_IDEAS_WITH_AI = "more_ideas_with_ai";
// const CHAT_TYPE_KEYWORD_TO_IDEA = "keyword_to_idea";

const Part2Page = () => {
  const theme = useTheme();
  const [groupSelectedId, setGroupSelectedId] = useState(null);
  const [refreshQ, setRefreshQ] = useState(false);
  const { data: dataListGroup } = useGetGroupQuestionPart2All({
    query: {
      skip: 0,
      limit: Number.MAX_SAFE_INTEGER,
      type: ETypeItem["group.question.part2"],
    },
  });
  const navigate = useNavigate();
  const [questionSelected, setQuestionSelected] = useState(null);
  // const [chatType, setChatType] = useState<string | null>(CHAT_TYPE_MORE_IDEAS_WITH_AI);

  useEffect(() => {
    setRefreshQ(true);
  }, [groupSelectedId]);
  useEffect(() => {
    if (refreshQ) {
      setRefreshQ(false);
    }
  }, [refreshQ]);

  const groupSelected = useMemo(() => {
    return dataListGroup?.find((item) => item?._id === groupSelectedId);
  }, [groupSelectedId]);

  return (
    <Box>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={60} minSize={50}>
          <LayoutTab
            title={"Topic & Question"}
            subTitle={"Choose topic to learn"}
            breadcrumbs={[
              {
                text: "Learn",
                link: ROUTES.LEARN,
              },
              {
                text: `Topic & Question`,
              },
            ]}
            listTab={[]}
            sxContainer={{}}
          >
            <Fragment>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                {dataListGroup?.map((item: any) => {
                  return (
                    <Box
                      sx={{
                        flex: 1,
                        border: "1px solid #D9D9D9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        "& img": {
                          maxWidth: "calc(60%)",
                          borderRadius: "5px",
                        },
                        "&:hover": {
                          border: `1px solid ${theme.palette.primary.main}`,
                          "& *": {
                            color: theme.palette.primary.main,
                            fontWeight: "bold",
                          },
                        },
                        ...(item._id === groupSelectedId
                          ? {
                              border: `1px solid ${theme.palette.primary.main}`,
                              "& *": {
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              },
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setGroupSelectedId(item?._id);
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "14px",
                          marginBottom: "10px",
                          "& *": {
                            fontSize: "14px",
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: item.name || "" }}
                      ></Box>
                      <img src={item.imageUrl} />
                    </Box>
                  );
                })}
              </Box>
              <Box>
                {!refreshQ && groupSelected && (
                  <ListQuestionPart2
                    groupSelected={groupSelected}
                    questionSelected={questionSelected}
                    setQuestionSelected={(item) => {
                      const url = parseParamUrl(
                        ROUTES.LEARN_QUESTION_PAGE.replace(":questionId", item._id),
                        undefined,
                        {
                          "type-question": "P2",
                        }
                      );
                      navigate(url);
                    }}
                  />
                )}
              </Box>
            </Fragment>
          </LayoutTab>
        </Panel>
        {/* <PanelResizeHandle
          style={{
            width: "5px",
            backgroundColor: "#e1e1e1",
          }}
        />
        <Panel
          defaultSize={40}
          style={{
            minWidth: "320px",
          }}
        >
          {questionSelected && (
            <>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      color: "#6A6969",
                      display: "flex",
                      fontSize: "12px",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "5px 10px",
                      borderRadius: "2px",
                      ...(chatType === CHAT_TYPE_KEYWORD_TO_IDEA
                        ? {
                            backgroundColor: "#fed8d8",
                            color: "#c44141",
                          }
                        : {}),
                    }}
                    onClick={() => {
                      setChatType((prev) => (prev !== CHAT_TYPE_KEYWORD_TO_IDEA ? CHAT_TYPE_KEYWORD_TO_IDEA : null));
                    }}
                  >
                    <AbcIcon
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    Keywords to idea
                  </Box>
                  <Box
                    sx={{
                      color: "#6A6969",
                      display: "flex",
                      fontSize: "12px",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "5px 10px",
                      borderRadius: "2px",
                      ...(chatType === CHAT_TYPE_MORE_IDEAS_WITH_AI
                        ? {
                            backgroundColor: "#F3F3FF",
                            color: theme.palette.primary.main,
                          }
                        : {}),
                    }}
                    onClick={() => {
                      setChatType((prev) =>
                        prev !== CHAT_TYPE_MORE_IDEAS_WITH_AI ? CHAT_TYPE_MORE_IDEAS_WITH_AI : null
                      );
                    }}
                  >
                    <TipsAndUpdatesIcon
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    More ideas with AI
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#6A6969",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Chat with Bot
                </Typography>
                {chatType === CHAT_TYPE_KEYWORD_TO_IDEA && (
                  <Box>
                    <KeywordToIdea isPartTwo={true} question={questionSelected} />
                  </Box>
                )}
                {chatType === CHAT_TYPE_MORE_IDEAS_WITH_AI && <ChatWithBotPart2 question={questionSelected} />}
              </Box>
            </>
          )}
        </Panel> */}
      </PanelGroup>
    </Box>
  );
};
export default Part2Page;
