import { ROUTES } from "@/constants";
import { Box } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
interface WordBookBtnProps {
  questionId: string;
}
const WordBookBtn = ({ questionId }: WordBookBtnProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        cursor: "pointer",
        fontSize: "14px",
        backgroundColor: "#FF9D23",
        color: "#fff",
        borderRadius: "16px",
        padding: "2px 8px",
      }}
      onClick={(event) => {
        event.stopPropagation();
        navigate(ROUTES.CMS_QUESTION_WORDBOOK.replace(":questionId", questionId));
      }}
    >
      Wordbook
    </Box>
  );
};
export default WordBookBtn;
